import { Title } from "@mui/icons-material";
import { SVGAttributes } from "react";

/**
 * Graphql ID's are opaque strings, we would use `NewType` here, but the generated types wouldn't line up.
 * This is still useful to differentiate between places which are expecting a "UUID"
 */
export type ID = string;
export type SvgProps = SVGAttributes<SVGElement>;
export type HexColor = string;
export type TimeZone = string;
export type ISO8601DateTime = string;
export type MuiIcon = typeof Title;

export const validRegions = ["us", "eu", "ap"] as const;
export type Region = (typeof validRegions)[number];

type JsonPrimitive = number | null | string | boolean;
type JsonObject = { [key: string]: Json };
type JsonArray = Array<Json>;
export type Json = JsonObject | JsonArray | JsonPrimitive;
export type Jwt = string;

/** This allows a type to be extracted from another type based on its __typename
 * e.g. if you have a GeneratedType which has a typename of either "User" or "Account"
 * you could do the following to get the UserType:
 * ```
 * type UserType = ExtractByTypename<"User", SomeGeneratedUnionType>
 * ```
 */
export type ExtractByTypename<
  Kind,
  Items extends { readonly __typename: string },
> = Items extends any // eslint-disable-line @typescript-eslint/no-explicit-any
  ? Items["__typename"] extends Kind
    ? Items
    : never
  : never;
