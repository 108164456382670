import React from "react";
import { SvgProps } from "../helpers/types";

export const TeamworksLogo = (props: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path d="M8.7544 0.346101C9.55836 -0.117725 10.5458 -0.115198 11.3474 0.352737L17.6998 4.06085C18.5044 4.53053 19 5.39793 19 6.33657V13.6504C19 14.5876 18.5059 15.4539 17.7032 15.9241L11.3508 19.6453C10.5474 20.1159 9.55667 20.1184 8.75095 19.6519L2.3082 15.9218C1.49907 15.4534 1 14.5834 1 13.6415V6.34546C1 5.40206 1.50057 4.53102 2.31163 4.0631L8.7544 0.346101ZM12.6536 9.08837L14.0932 8.19585C14.227 8.1127 14.2691 7.93561 14.1869 7.80024L13.8111 7.18163C13.7288 7.04612 13.5533 7.00368 13.4191 7.08697L11.5136 8.2698C11.0651 8.54817 10.5488 8.69563 10.0222 8.69563H9.9779C9.45131 8.69563 8.93499 8.54817 8.48631 8.2698L6.58081 7.08697C6.44678 7.00368 6.27129 7.04612 6.18882 7.18163L5.81322 7.80024C5.73089 7.93561 5.77291 8.1127 5.90694 8.19585L7.3464 9.08837C8.02582 9.50988 8.30642 9.68539 8.60126 10.0235C8.88998 10.3554 9.1089 10.7467 9.25504 11.1919C9.32298 11.3988 9.35204 11.617 9.35232 11.835L9.35574 14.1613C9.35603 14.3202 9.48351 14.4486 9.64061 14.4486H10.3595C10.5164 14.4486 10.6441 14.3202 10.6444 14.1613L10.6476 11.835C10.6479 11.617 10.677 11.3988 10.7451 11.1919C10.8911 10.7467 11.11 10.3554 11.3988 10.0235C11.6935 9.68539 11.9743 9.50988 12.6536 9.08837Z" />
    </svg>
  );
};

export const TeamworksWordmark = (props: SvgProps) => {
  return (
    <svg viewBox="0 0 404 101" {...props}>
      <path d="M85.0129 20.1101L53.1308 1.87866C48.7495 -0.62622 43.3494 -0.62622 38.9681 1.87866L7.07669 20.1101C2.69544 22.615 0 27.2486 0 32.2584V68.7305C0 73.7403 2.69544 78.3738 7.07669 80.8787L38.9589 99.1194C43.3401 101.624 48.7402 101.624 53.1215 99.1194L85.0037 80.8787C89.3849 78.3738 92.0804 73.7403 92.0804 68.7305V32.2584C92.0804 27.2486 89.3849 22.615 85.0037 20.1101H85.0129ZM67.0989 41.6631L59.6887 46.1591C56.1967 48.2878 54.7517 49.1778 53.2419 50.8752C51.7599 52.5543 50.6298 54.527 49.8796 56.775C49.5276 57.8118 49.3794 58.9129 49.3794 60.0139L49.3608 71.7492C49.3608 72.5475 48.7032 73.1989 47.8973 73.1989H44.2015C43.3957 73.1989 42.738 72.5475 42.738 71.7492L42.7195 60.0139C42.7195 58.9129 42.562 57.8118 42.2193 56.775C41.469 54.527 40.339 52.5543 38.857 50.8752C37.3379 49.1778 35.8929 48.2878 32.4009 46.1591L25 41.6631C24.3053 41.2411 24.0922 40.351 24.5183 39.6629L26.445 36.5433C26.871 35.8643 27.7788 35.6441 28.4642 36.0661L38.2642 42.0301C40.5706 43.434 43.229 44.1864 45.9337 44.1864H46.1652C48.8699 44.1864 51.5283 43.434 53.8347 42.0301L63.6346 36.0661C64.3201 35.6441 65.2186 35.8643 65.6446 36.5433L67.5805 39.6629C67.9974 40.351 67.7843 41.2411 67.0989 41.6631Z" />
      <path d="M145.49 36.9107H122.565C122.287 36.9107 122.111 37.0942 122.111 37.3603V42.3701C122.111 42.6453 122.296 42.8197 122.565 42.8197H130.401C130.586 42.8197 130.669 42.9114 130.669 43.0949V67.162C130.669 67.4372 130.855 67.6116 131.123 67.6116H136.912C137.181 67.6116 137.366 67.428 137.366 67.162V43.0949C137.366 42.9114 137.459 42.8197 137.635 42.8197H145.471C145.74 42.8197 145.925 42.6362 145.925 42.3701V37.3603C145.925 37.0851 145.74 36.9107 145.471 36.9107H145.49Z" />
      <path d="M172.527 36.9103H152.659C152.381 36.9103 152.205 37.0939 152.205 37.3599V67.1616C152.205 67.4369 152.39 67.6112 152.659 67.6112H172.527C172.796 67.6112 172.981 67.4277 172.981 67.1616V62.3262C172.981 62.0509 172.796 61.8766 172.527 61.8766H159.171C158.985 61.8766 158.893 61.7848 158.893 61.6013V55.2336C158.893 55.0501 158.985 54.9583 159.171 54.9583H170.295C170.573 54.9583 170.749 54.7748 170.749 54.5087V49.7192C170.749 49.4439 170.564 49.2696 170.295 49.2696H159.171C158.985 49.2696 158.893 49.1778 158.893 48.9943V42.9019C158.893 42.7184 158.985 42.6266 159.171 42.6266H172.527C172.796 42.6266 172.981 42.4431 172.981 42.177V37.3416C172.981 37.0663 172.796 36.892 172.527 36.892V36.9103Z" />
      <path d="M195.768 37.3603C195.675 37.0851 195.499 36.9107 195.175 36.9107H189.432C189.108 36.9107 188.932 37.0942 188.839 37.3603L178.169 67.162C178.076 67.4372 178.169 67.6116 178.484 67.6116H184.458C184.773 67.6116 184.958 67.4739 185.051 67.162L186.783 61.7852H197.63L199.408 67.162C199.501 67.4739 199.677 67.6116 200.001 67.6116H205.929C206.244 67.6116 206.337 67.428 206.244 67.162L195.759 37.3603H195.768ZM188.654 56.1423L192.165 45.398H192.304L195.814 56.1423H188.654Z" />
      <path d="M240.339 36.9107H235.004C234.643 36.9107 234.411 37.0484 234.272 37.3603L226.566 54.8761H226.38L218.544 37.3603C218.405 37.0484 218.183 36.9107 217.812 36.9107H212.385C212.116 36.9107 211.931 37.0942 211.931 37.3603V67.162C211.931 67.4372 212.116 67.6116 212.385 67.6116H217.535C217.812 67.6116 217.988 67.428 217.988 67.162V49.8664H218.174L223.917 62.822C224.102 63.2257 224.37 63.4092 224.787 63.4092H227.927C228.335 63.4092 228.613 63.2257 228.798 62.822L234.541 49.8664H234.726V67.162C234.726 67.4372 234.911 67.6116 235.18 67.6116H240.33C240.599 67.6116 240.784 67.428 240.784 67.162V37.3603C240.784 37.0851 240.599 36.9107 240.33 36.9107H240.339Z" />
      <path d="M284.912 36.9107H279.077C278.762 36.9107 278.576 37.0484 278.53 37.3603L274.241 56.6378H274.149L268.684 37.3603C268.591 37.0851 268.406 36.9107 268.137 36.9107H264.173C263.849 36.9107 263.673 37.0942 263.58 37.3603L258.291 56.6378H258.198L253.734 37.3603C253.688 37.0851 253.502 36.9107 253.187 36.9107H247.306C246.991 36.9107 246.898 37.0942 246.944 37.3603L254.966 67.162C255.058 67.4372 255.244 67.6116 255.512 67.6116H260.07C260.384 67.6116 260.57 67.428 260.662 67.162L266.09 48.0588H266.183L271.565 67.162C271.657 67.4372 271.833 67.6116 272.157 67.6116H276.715C277.03 67.6116 277.261 67.428 277.307 67.162L285.283 37.3603C285.329 37.0851 285.236 36.9107 284.921 36.9107H284.912Z" />
      <path d="M301.881 36.4151C296.453 36.4151 292.173 38.9016 290.58 43.8655C289.895 45.985 289.719 47.7008 289.719 52.2609C289.719 56.8211 289.904 58.5369 290.58 60.6564C292.173 65.6203 296.462 68.1068 301.881 68.1068C307.299 68.1068 311.588 65.6203 313.181 60.6564C313.867 58.5369 314.052 56.8211 314.052 52.2609C314.052 47.7008 313.867 45.985 313.181 43.8655C311.588 38.9016 307.299 36.4151 301.881 36.4151ZM306.762 58.858C306.077 60.9776 304.437 62.2438 301.881 62.2438C299.324 62.2438 297.685 60.9776 296.999 58.858C296.638 57.7754 296.499 56.2889 296.499 52.2701C296.499 48.2513 296.638 46.7649 296.999 45.6822C297.685 43.5627 299.324 42.2965 301.881 42.2965C304.437 42.2965 306.077 43.5627 306.762 45.6822C307.123 46.7649 307.262 48.2513 307.262 52.2701C307.262 56.2889 307.123 57.7754 306.762 58.858Z" />
      <path d="M338.811 54.9679C342.229 53.4815 344.322 50.3618 344.322 46.4348C344.322 40.8378 340.126 36.9107 333.791 36.9107H321.434C321.166 36.9107 320.98 37.0942 320.98 37.3603V67.162C320.98 67.4372 321.166 67.6116 321.434 67.6116H327.224C327.492 67.6116 327.677 67.428 327.677 67.162V56.1882C327.677 56.0047 327.77 55.9129 327.946 55.9129H332.096L337.431 67.1528C337.57 67.4648 337.709 67.6024 338.116 67.6024H344.591C344.915 67.6024 345.045 67.3271 344.915 67.061L338.811 54.9587V54.9679ZM333.43 50.2701H327.965C327.779 50.2701 327.696 50.1783 327.696 49.9948V42.9022C327.696 42.7187 327.789 42.627 327.965 42.627H333.43C335.986 42.627 337.626 44.1134 337.626 46.4164C337.626 48.7194 335.986 50.2517 333.43 50.2517V50.2701Z" />
      <path d="M366.526 49.1507L375.919 37.4979C376.15 37.2227 376.011 36.9107 375.733 36.9107H369.12C368.758 36.9107 368.573 37.0025 368.342 37.3144L358.042 50.6371V37.3603C358.042 37.0851 357.856 36.9107 357.588 36.9107H351.798C351.53 36.9107 351.345 37.0942 351.345 37.3603V67.162C351.345 67.4372 351.53 67.6116 351.798 67.6116H357.588C357.856 67.6116 358.042 67.428 358.042 67.162V59.3537L362.099 54.2981L369.12 67.2078C369.305 67.5198 369.435 67.6116 369.805 67.6116H376.502C376.817 67.6116 376.956 67.3822 376.771 67.0702L366.517 49.1415L366.526 49.1507Z" />
      <path d="M394.249 49.1505L392.609 48.9211C389.052 48.4256 387.774 47.2971 387.774 45.5354C387.774 43.5902 389.191 42.1955 391.924 42.1955C394.48 42.1955 396.759 43.0122 399.121 44.5444C399.352 44.6821 399.575 44.6362 399.76 44.4068L402.539 40.2962C402.77 39.9843 402.724 39.8008 402.446 39.6173C399.843 37.6262 396.018 36.4151 391.914 36.4151C385.217 36.4151 380.929 40.1586 380.929 45.8106C380.929 51.4627 384.439 54.1602 390.729 55.0227L392.368 55.2521C396.064 55.7476 397.157 56.8762 397.157 58.6837C397.157 60.8491 395.332 62.3447 392.1 62.3447C388.867 62.3447 386.042 60.8124 383.986 59.1884C383.754 59.0049 383.485 59.0049 383.3 59.2342L379.836 63.1613C379.604 63.4366 379.697 63.7485 379.882 63.932C382.3 66.2351 386.996 68.1344 391.822 68.1344C399.843 68.1344 403.993 64.0238 403.993 58.4727C403.993 52.9216 400.621 50.0313 394.239 49.1688L394.249 49.1505Z" />
    </svg>
  );
};
