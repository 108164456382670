import React from "react";
// eslint-disable-next-line no-restricted-imports
import { Tooltip as MuiTooltip, TooltipProps } from "@mui/material";
import { AppText } from "./Typography";
import { fontSize } from "../helpers/theme";

export const Tooltip = ({
  enabled,
  title,
  arrow,
  ...muiProps
}: TooltipProps & {
  enabled?: boolean;
}) =>
  enabled === false ? (
    muiProps.children
  ) : (
    <MuiTooltip
      {...muiProps}
      arrow={arrow ?? true}
      componentsProps={{ popper: { style: { pointerEvents: "none" } } }}
      title={<AppText style={{ fontSize: fontSize.small }}>{title}</AppText>}
    />
  );
