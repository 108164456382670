import React from "react";
import { SvgProps } from "../helpers/types";
import { colors } from "../helpers/theme";

export const DigitalDisplaysIcon = (props: SvgProps) => {
  return (
    <svg viewBox="0 0 57 64" fill="none" {...props}>
      <path
        d="M56.3929 44.2371V18.9229C56.3929 16.6672 55.1898 14.612 53.2349 13.5092L31.3294 0.827096C29.3744 -0.275699 27.0185 -0.275699 25.0635 0.827096L3.158 13.4591C1.20305 14.612 0 16.6672 0 18.9229V44.2371C0 46.4427 1.20305 48.548 3.158 49.6508L25.0635 62.3329C27.0185 63.4357 29.3744 63.4357 31.3294 62.3329L53.2349 49.6508C55.1898 48.548 56.3929 46.4427 56.3929 44.2371Z"
        fill={colors.blue2}
      />
      <path
        d="M34.4373 31.9058L16.2412 30.4521C15.6898 30.4521 15.2888 29.8506 15.2888 29.2491V20.0257C15.2888 19.3741 15.74 18.9229 16.2412 18.973L34.4373 20.4267C34.9887 20.4267 35.3897 21.0282 35.3897 21.6298V30.8531C35.3897 31.5048 34.9386 31.9559 34.4373 31.9058Z"
        fill={colors.white}
      />
      <path
        d="M45.2146 40.9289L38.0966 40.3775C37.6955 40.3775 37.3948 39.9264 37.3948 39.4752V21.5799C37.3948 21.1287 37.6955 20.7778 38.0966 20.7778L45.2146 21.3292C45.6156 21.3292 45.9164 21.7804 45.9164 22.2315V40.1269C45.9164 40.578 45.6156 40.9289 45.2146 40.9289Z"
        fill={colors.white}
      />
      <path
        d="M34.6374 40.1769L26.968 39.5753C26.567 39.5753 26.2161 39.1242 26.2161 38.6229V34.4123C26.2161 33.911 26.567 33.5601 26.968 33.6102L34.6374 34.2118C35.0384 34.2118 35.3893 34.6629 35.3893 35.1642V39.3748C35.3893 39.8761 35.0384 40.227 34.6374 40.1769Z"
        fill={colors.white}
      />
      <path
        d="M23.6599 39.2745L15.9904 38.673C15.5894 38.673 15.2385 38.2219 15.2385 37.7206V33.5099C15.2385 33.0086 15.5894 32.6578 15.9904 32.7079L23.6599 33.3094C24.0609 33.3094 24.4118 33.7606 24.4118 34.2618V38.4725C24.4118 38.9738 24.0609 39.3247 23.6599 39.2745Z"
        fill={colors.white}
      />
      <path
        d="M41.8561 45.5903C41.8561 45.5903 41.806 45.5903 41.7559 45.5903L13.2336 43.3346C11.5794 43.1842 10.2761 41.6303 10.2761 39.8257V23.4342C10.2761 22.7825 10.7774 22.2812 11.429 22.2812C12.0807 22.2812 12.582 22.7825 12.582 23.4342V39.8257C12.582 40.4774 12.983 40.9786 13.384 41.0288L41.9063 43.2845C42.5579 43.3346 43.0592 43.886 43.0091 44.5376C43.0091 45.1392 42.4577 45.6404 41.8561 45.6404V45.5903Z"
        fill={colors.blue1}
      />
    </svg>
  );
};
