import React, { Suspense, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ConnectedTv,
  ExitToApp,
  SwapHoriz,
  Close,
  Menu as MenuIcon,
  HiveRounded,
  SubscriptionsRounded,
  ViewComfyRounded,
  ConnectedTvRounded,
  CampaignRounded,
  ColorLensRounded,
  HelpRounded,
} from "@mui/icons-material";
import { Column, Row } from "./Flex";
import { LoadingScreen } from "./LoadingScreen";
import {
  borderRadius,
  colors,
  fontSize,
  iconSize,
  isDark,
  spacing,
  toRgba,
} from "../helpers/theme";
import { makeStyles, useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { AppText, HelperText } from "./Typography";
import { useScreenSize } from "../providers/ScreenSizeProvider";
import classNames from "classnames";
import { TeamworksLogo } from "../assets/TeamworksLogo";
import {
  featureFlagEnabled,
  useCurrentOrganisation,
  useCurrentUser,
} from "../providers/CurrentProvider";
import { Dialog, Divider, Menu, MenuItem } from "@mui/material";
import { OrganisationSwitcher } from "./OrganisationSwitcher";
import appJson from "../assets/app.json";
import { useAuthenticatedAuth } from "../providers/AuthProvider";
import { config } from "../config";
import { Tooltip } from "./Tooltip";
import { AppSwitcher } from "./AppSwitcher";
import { switchLocale } from "../helpers/localise";
import { useInitialSearchParams } from "../providers/InitialSearchParamsProvider";

type LayoutNavLinkType = {
  id?: string;
  label: string;
  icon: typeof ConnectedTv;
  to: string;
  expanded: boolean;
  position?: "top" | "bottom";
  inMoreMenu?: boolean;
  openInNew?: boolean;
  onClick?: (el: HTMLElement) => void;
  hidden?: boolean;
};

const useClasses = makeStyles((theme) => ({
  page: {
    display: "flex",
    flexDirection: "column-reverse",
    height: "100%",
    backgroundColor: theme.palette.primary.main,
  },
  pageDesktop: {
    flexDirection: "row",
  },
  navBar: {
    display: "flex",
    flexDirection: "row",
    flexShrink: 0,
    justifyContent: "stretch",
    width: "100vw",
    height: 64,
    alignItems: "center",
    background: `${theme.palette.primary.main} radial-gradient(circle, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.main} 100%)`,
    backgroundPosition: "-200px 50vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: 600,
  },
  navBarMedium: {
    width: 80,
    height: "auto",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: spacing.smaller,
  },
  navBarLarge: {
    width: 270,
    flexDirection: "column",
    height: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  twLogo: {
    width: iconSize.large,
    height: iconSize.large,
    margin: `${spacing.large}px ${spacing.medium}px 0`,
    fill: colors.white,
  },
  navLink: {
    flexGrow: 1,
    display: "flex",
    textDecoration: "none",
    padding: `${spacing.smallest}px ${spacing.smaller}px`,
    alignItems: "center",
    justifyContent: "center",
    gap: spacing.smaller,
    borderRadius: borderRadius.regular,
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.15)",
    },
  },
  navLinkMedium: {
    width: "100%",
    flexGrow: 0,
    borderRadius: borderRadius.regular,
    justifyContent: "center",
    paddingTop: spacing.smallest,
    paddingBottom: spacing.smallest,
  },
  navLinkLarge: {
    width: "100%",
    flexGrow: 0,
    borderRadius: borderRadius.regular,
    alignItems: "center",
    paddingTop: spacing.smallest,
    paddingBottom: spacing.smallest,
    justifyContent: "flex-start",
  },
  navLinkActive: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  navLinkActiveMedium: {
    backgroundColor: "rgba(255,255,255,0.15)",
  },
  navLinkActiveLarge: {
    backgroundColor: "rgba(255,255,255,0.15)",
  },
  navLinkButton: {
    display: "flex",
    alignItems: "center",
    gap: spacing.smaller,
    "& .MuiButtonBase-root": {
      display: "none",
      justifyContent: "center",
    },
  },
  navLinkText: {
    fontSize: fontSize.small,
    fontWeight: 600,
    color: colors.white,
  },
  mainContent: {
    flexGrow: 1,
    overflow: "hidden",
  },
  mainContentMedium: {
    borderTopLeftRadius: 30,
    borderTop: `8px solid ${theme.palette.primary.main}`,
  },
  mainContentInner: {
    flexGrow: 1,
    overflowY: "auto",
    backgroundColor: colors.white,
  },
  currentUserContainer: {
    width: `calc(100% - ${spacing.small * 2}px)`,
    margin: spacing.small,
    marginBottom: spacing.smaller,
    borderRadius: borderRadius.regular,
    padding: spacing.smallest,
    alignItems: "center",
    gap: spacing.smaller,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: toRgba(colors.white, 0.15),
    },
  },
  currentUserContainerMedium: {
    width: "100%",
    margin: 0,
    padding: `${spacing.smaller}px 0`,
    justifyContent: "center",
  },
  currentUserContainerSmall: {
    width: "auto",
    margin: 0,
    padding: spacing.smaller,
    justifyContent: "center",
  },
  whiteText: {
    color: colors.white,
  },
  currentUserIconWrapper: {
    backgroundColor: colors.gray6,
    borderRadius: 100,
    width: iconSize.large,
    height: iconSize.large,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  currentUserAvatar: {
    width: iconSize.large,
    height: iconSize.large,
  },
  currentUserInitials: {
    color: colors.white,
    fontSize: fontSize.small,
    fontWeight: 600,
  },
  versionRow: {
    background: colors.gray1,
    borderTop: `1px solid ${colors.gray3}`,
    justifyContent: "flex-end",
    paddingRight: spacing.smaller,
    paddingTop: spacing.tiny,
    marginTop: spacing.smallest,
  },
  orgSwitcherCard: {
    backgroundColor: "rgba(255,255,255,0.15)",
    padding: spacing.smallest,
    border: `1px solid rgba(255,255,255,0.2)`,
    borderRadius: borderRadius.regular,
    alignItems: "center",
    marginBottom: spacing.small,
  },
  orgSwitcherCardClickable: {
    "&:hover": { cursor: "pointer" },
  },
  orgSwitcherCardMedium: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    justifyContent: "center",
  },
  orgSwitcherLogo: {
    width: iconSize.large,
    height: iconSize.large,
    background: "no-repeat center",
    backgroundSize: "contain",
  },
  orgSwitcherText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: colors.white,
    flexGrow: 1,
  },
  logoutButton: {
    width: 220,
    justifyContent: "space-between",
    color: colors.red3,
  },
  currentUserName: {
    color: colors.white,
    fontSize: fontSize.small,
    fontWeight: 600,
  },
  currentUserOrg: {
    color: colors.white,
    opacity: 0.7,
    fontSize: fontSize.detail,
  },
}));

export const Layout = (props: { children: React.ReactNode }) => {
  const { t } = useTranslation("Layout");
  const screenSize = useScreenSize();
  const classes = useClasses();
  const { handleLogout, allUserProfiles } = useAuthenticatedAuth();
  const currentUser = useCurrentUser();
  const currentOrganisation = useCurrentOrganisation();
  const navigate = useNavigate();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement>();
  const [state, setState] = useState<"idle" | "switching-org" | "signing-out">(
    "idle",
  );
  const [sidebarState, setSidebarState] = useState<"open" | "closed">();
  const [appSwitcherState, setAppSwitcherState] = useState<
    { tag: "closed" } | { tag: "open"; el: Element }
  >({ tag: "closed" });

  const sidebarOpen =
    (sidebarState ?? (screenSize === "lg" ? "open" : "closed")) === "open";

  const navbarRef = useRef<HTMLDivElement>(null);

  const navLinks: Omit<LayoutNavLinkType, "expanded">[] = [
    {
      label: t(sidebarOpen ? "Close Menu" : "Open Menu"),
      to: "#",
      icon: sidebarOpen ? Close : MenuIcon,
      onClick: () => setSidebarState(sidebarOpen ? "closed" : "open"),
      hidden: screenSize === "sm",
    },
    {
      label: t("Devices"),
      icon: ConnectedTvRounded,
      to: "/devices",
      position: "top",
    },
    {
      label: t("Configurations"),
      icon: ViewComfyRounded,
      to: "/configs",
      position: "top",
    },
    {
      label: t("Playlists"),
      icon: SubscriptionsRounded,
      to: "/playlists",
      position: "top",
      inMoreMenu: true,
      hidden: !featureFlagEnabled("playlists"),
    },
    {
      label: t("Announcements"),
      icon: CampaignRounded,
      to: "/announcements",
      position: "top",
    },
    {
      label: t("Themes"),
      icon: ColorLensRounded,
      to: "/themes",
      inMoreMenu: true,
      position: "top",
    },
    {
      label: t("Teamworks Help"),
      icon: HelpRounded,
      to: config.customerSupportLink,
      inMoreMenu: true,
      position: "bottom",
      openInNew: true,
    },
    {
      label: t("Teamworks Apps"),
      to: "#",
      icon: HiveRounded,
      inMoreMenu: true,
      position: "bottom",
      onClick: (el) => {
        setAppSwitcherState({
          tag: "open",
          el: screenSize === "sm" ? navbarRef.current ?? el : el,
        });
      },
    },
  ];

  const { source } = useInitialSearchParams();
  const hideNavigation = source === "tw_web_view";

  return (
    <div
      className={classNames(
        classes.page,
        screenSize === "md" || screenSize === "lg"
          ? classes.pageDesktop
          : undefined,
      )}
    >
      {!hideNavigation && (
        <div
          ref={navbarRef}
          className={classNames(
            classes.navBar,
            screenSize === "sm"
              ? undefined
              : sidebarOpen
                ? classes.navBarLarge
                : classes.navBarMedium,
          )}
        >
          {screenSize !== "sm" && <TeamworksLogo className={classes.twLogo} />}
          <Column
            grow={1}
            fullWidth
            style={{
              paddingTop:
                screenSize === "sm"
                  ? 0
                  : sidebarOpen
                    ? spacing.medium
                    : spacing.smallest,
            }}
            direction={screenSize === "sm" ? "row" : "column"}
            gap={screenSize === "sm" ? "small" : "smallest"}
            justifyContent={screenSize === "sm" ? "stretch" : "flex-start"}
            paddingHorizontal={
              screenSize === "sm"
                ? "smallest"
                : sidebarOpen
                  ? "small"
                  : "smallest"
            }
          >
            {screenSize !== "sm" && (
              <Row
                className={classNames(
                  classes.orgSwitcherCard,
                  sidebarOpen ? undefined : classes.orgSwitcherCardMedium,
                  allUserProfiles.length > 1
                    ? classes.orgSwitcherCardClickable
                    : undefined,
                )}
                gap="smaller"
                onClick={() => {
                  if (allUserProfiles.length > 1) {
                    setState("switching-org");
                  }
                }}
              >
                <div
                  style={{
                    backgroundImage: `url(${currentOrganisation.navbarImageUrl})`,
                  }}
                  className={classes.orgSwitcherLogo}
                />
                {sidebarOpen && (
                  <>
                    <HelperText className={classes.orgSwitcherText}>
                      {currentOrganisation.name}
                    </HelperText>
                    {allUserProfiles.length > 1 && (
                      <SwapHoriz
                        sx={{
                          width: iconSize.smaller,
                          height: iconSize.smaller,
                          color: colors.gray4,
                        }}
                        style={{ marginRight: spacing.tiny }}
                      />
                    )}
                  </>
                )}
              </Row>
            )}
            {navLinks
              .filter((l) => (l.position ?? "top") === "top")
              .map((navLink) =>
                navLink.hidden ? null : (
                  <LayoutNavLink
                    key={navLink.label}
                    {...navLink}
                    expanded={sidebarOpen}
                  />
                ),
              )}
            {screenSize !== "sm" && <Column grow={1} />}
            {navLinks
              .filter((l) => l.position === "bottom")
              .map((navLink) =>
                navLink.hidden ? null : (
                  <LayoutNavLink
                    key={navLink.label}
                    {...navLink}
                    expanded={sidebarOpen}
                  />
                ),
              )}
          </Column>
          <Row
            className={classNames(
              classes.currentUserContainer,
              screenSize === "sm"
                ? classes.currentUserContainerSmall
                : sidebarOpen
                  ? undefined
                  : classes.currentUserContainerMedium,
            )}
            onClick={(ev) => setMenuAnchor(ev.currentTarget)}
          >
            <Row className={classes.currentUserIconWrapper}>
              {currentUser.avatarUrl == null ? (
                <AppText className={classes.currentUserInitials}>
                  {currentUser.initials}
                </AppText>
              ) : (
                <img
                  src={currentUser.avatarUrl}
                  className={classes.currentUserAvatar}
                />
              )}
            </Row>
            {sidebarOpen && screenSize !== "sm" && (
              <Column>
                <AppText className={classes.currentUserName}>
                  {currentUser.fullName}
                </AppText>
                <HelperText className={classes.currentUserOrg}>
                  {currentOrganisation.name}
                </HelperText>
              </Column>
            )}
          </Row>
          <AppSwitcher
            anchorEl={
              appSwitcherState.tag === "open" ? appSwitcherState.el : undefined
            }
            onClose={() => setAppSwitcherState({ tag: "closed" })}
          />
          <Menu
            autoFocus={false}
            anchorEl={menuAnchor}
            onClose={() => setMenuAnchor(undefined)}
            open={menuAnchor != null}
            transformOrigin={{ horizontal: "left", vertical: "bottom" }}
            anchorOrigin={{
              horizontal: "right",
              vertical: screenSize === "sm" ? "top" : "bottom",
            }}
          >
            {screenSize === "sm" &&
              navLinks.map((navLink) => {
                if (!navLink.inMoreMenu || navLink.hidden) return null;
                return (
                  <MenuItem
                    key={navLink.to}
                    onClick={(ev) => {
                      setMenuAnchor(undefined);

                      if (navLink.onClick != null) {
                        navLink.onClick(ev.currentTarget);
                      } else if (navLink.openInNew) {
                        window.open(navLink.to, "_blank");
                      } else {
                        navigate(navLink.to);
                      }
                    }}
                  >
                    <Row gap="smallest" alignItems="center">
                      <navLink.icon />
                      {navLink.label}
                    </Row>
                  </MenuItem>
                );
              })}
            {screenSize === "sm" && <Divider />}
            {allUserProfiles.length > 1 && screenSize === "sm" && (
              <MenuItem
                onClick={() => {
                  setMenuAnchor(undefined);
                  setState("switching-org");
                }}
              >
                <Row gap="smallest" alignItems="center">
                  <SwapHoriz />
                  {t("Switch organisation")}
                </Row>
              </MenuItem>
            )}
            {config.isDevelopment && (
              <MenuItem
                onClick={() => {
                  setMenuAnchor(undefined);
                  switchLocale("en-US");
                }}
              >
                Disable translations
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                handleLogout();
                setMenuAnchor(undefined);
                setState("signing-out");
              }}
            >
              <Row
                gap="smallest"
                alignItems="center"
                className={classes.logoutButton}
              >
                {t("Logout")}
                <ExitToApp />
              </Row>
            </MenuItem>
            <Row className={classes.versionRow}>
              <HelperText>v{appJson.version}</HelperText>
            </Row>
          </Menu>
        </div>
      )}
      <Column
        className={classNames(
          classes.mainContent,
          screenSize !== "sm" && !hideNavigation
            ? classes.mainContentMedium
            : undefined,
        )}
      >
        <Column className={classes.mainContentInner}>
          <Suspense
            fallback={<LoadingScreen message={t("Loading, please wait...")} />}
          >
            {props.children}
          </Suspense>
        </Column>
      </Column>

      <OrganisationSwitcher
        open={state === "switching-org"}
        onClose={() => setState("idle")}
      />

      <Dialog fullWidth open={state === "signing-out"}>
        <Column padding="large">
          <LoadingScreen message={t("Signing you out")} />
        </Column>
      </Dialog>
    </div>
  );
};

const LayoutNavLink = ({ expanded, ...props }: LayoutNavLinkType) => {
  const screenSize = useScreenSize();
  const theme = useTheme();
  const classes = useClasses();

  const isCurrentPath = (path: string) =>
    (window.location.pathname === "/" && path === "/devices") ||
    window.location.pathname.startsWith(path);

  if (screenSize === "sm" && props.inMoreMenu) {
    return null;
  }

  const isActive = isCurrentPath(props.to);
  const secondaryTextColor = isActive
    ? isDark(theme.palette.secondary.main)
      ? colors.white
      : colors.black
    : colors.white;

  return (
    <Tooltip
      title={props.label}
      placement={screenSize === "sm" ? "top" : "right"}
      arrow
      enabled={!expanded || screenSize === "sm"}
    >
      <Link
        id={props.id}
        className={classNames(
          classes.navLink,
          screenSize === "sm"
            ? undefined
            : expanded
              ? classes.navLinkLarge
              : classes.navLinkMedium,
          isActive
            ? screenSize === "sm"
              ? classes.navLinkActive
              : expanded
                ? classes.navLinkActiveLarge
                : classes.navLinkActiveMedium
            : undefined,
        )}
        to={props.to}
        target={props.openInNew ? "_blank" : undefined}
        onClick={
          props.onClick != null
            ? (ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                props.onClick?.(ev.currentTarget);
              }
            : undefined
        }
      >
        <props.icon
          sx={{
            color: screenSize === "sm" ? secondaryTextColor : colors.white,
            width: iconSize.regular,
            height: iconSize.regular,
          }}
        />
        {screenSize !== "sm" && expanded && (
          <AppText className={classes.navLinkText}>{props.label}</AppText>
        )}
      </Link>
    </Tooltip>
  );
};
