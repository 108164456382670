import { type Localisation } from "./en";

export const portuguese: Localisation = {
  dateFormats: {
    datePickerLocale: "pt",
    timeFormat: "HH:mm", // 14:30
    dayMonthYearFormat: "do MMMM yyyy", // 11th January 1988
    dayMonthYearTimeFormat: "do MMMM yyyy, HH:mm", // 11th January 1988, 14:30
  },
  App: {
    "Digital Displays": "Monitores Digitais",
    Loading: "Carregando",
    "Checking authentication": "Verificando autenticação",
    "Redirecting to login": "Redirecionando para login",
    "Sign in with Teamworks": "Faça login com o Teamworks",
    Initalizing: "Inicializando",
    "Verifing authentication": "Verificando a autenticação",
    Redirecting: "Redirecionando",
    "Welcome back!": "Bem vindo de volta!",
    "Login to get started": "Faça login para começar",
  },
  AppSwitcher: {
    arms: "Compliance + Recruiting",
    hub: "Hub",
    inflcr: "Influencer",
    academics: "Academics",
    communities: "Communities",
    pulse: "Pulse",
    pathways: "Pathways",
    nutrition: "Nutrition",
    ams: "AMS",
    tv: "Digital Displays",
    trac: "Inventory Management",
    strength: "Strength + Conditioning",
    wallet: "Wallet",
  },
  Layout: {
    "Checking authentication": "Verificando autenticação",
    Dashboard: "Painel",
    Configurations: "Configurações",
    Devices: "Dispositivos",
    Themes: "Temas",
    Settings: "Configurações",
    Announcements: "Anúncios",
    "Teamworks Help": "Teamworks Ajuda",
    "Teamworks Apps": "Teamworks Aplicativos",
    "Privacy policy": "Política de Privacidade",
    Logout: "Sair",
    "Loading, please wait...": "Carregando, aguarde...",
    Users: "Usuários",
    "Switch organisation": "Mudar de organização",
    "Signing you out": "Desconectando você",
    "Open Menu": "Abrir Menu",
    "Close Menu": "Fechar menu",
    Playlists: "Listas de reprodução",
  },
  ConfigsScreen: {
    "{{totalCount}} Configurations": "{{totalCount}} Configurações",
    "New configuration": "Nova configuração",
    "Configurations allow you to customise the look and content of your displays.":
      "As configurações permitem que você personalize a aparência e o conteúdo dos seus monitores.",
    "Loading configurations": "Carregando configurações",
    Configurations: "Configurações",
    Name: "Nome",
    Template: "Modelo",
    Theme: "Tema",
    "Device count": "Contagem de dispositivos",
    "Created at": "Criado em",
    "No configurations have been setup yet":
      "Nenhuma configuração foi definida ainda",
    "No configurations found for '{{searchText}}'":
      "Nenhuma configuração encontrada para '{{searchText}}'",
    "Displays allow you to cast scheduled events and media to any device with an internet browser":
      "Os displays permitem que você transmita eventos e mídia programados para qualquer dispositivo com um navegador de internet",
    "Create one now": "Crie um agora",
    "Create configuration": "Criar configuração",
    "Add custom videos, YouTube videos and images":
      "Adicione vídeos personalizados, vídeos e imagens do YouTube",
    "Filter calendar to present a live schedule":
      "Filtre o calendário para apresentar uma programação ao vivo",
    "Cycle through important updates on cards":
      "Percorra atualizações importantes nos cartões",
    "Includes team crest, time and date":
      "Inclui escudo da equipe, hora e data",
    "Display a schedule over multiple days":
      "Exibir uma programação para vários dias",
    "Display daily schedule for different teams and groups.":
      "Exibir programação diária para diferentes equipes e grupos.",
    "Configuration deleted": "Configuração excluída",
    "Are you sure you want to delete this configuration?":
      "Tem certeza de que deseja excluir esta configuração?",
    "Delete configuration": "Excluir configuração",
    "Fetching configuration": "Buscando configuração",
    "Digital Displays URL": "URL de monitores digitais",
    "You may want to use this hardcoded url to integrate with systems that do not support our QR code setup.":
      "Você pode querer usar este URL codificado para integração com sistemas que não suportam nossa configuração de código QR.",
    "Please speak with your Teamworks administrator to ensure your IP addresses are permitted to access the link for security.":
      "Fale com o administrador do Teamworks para garantir que seus endereços IP tenham permissão para acessar o link por segurança.",
    "Something went wrong": "Algo deu errado",
    "Edit configuration": "Editar configuração",
    "Get hardcoded URL": "Obtenha URL codificado",
    "Includes background image from theme": "Inclui imagem de fundo do tema",
    "Last active at {{lastActiveAt}}": "Última atividade às {{lastActiveAt}}",
    "Loading screen devices": "Carregando dispositivos de tela",
    "Search configurations...": "Pesquisar configurações...",
    "Manage IP addresses": "Gerenciar endereços IP",
    "For systems which do not support our QR setup, these permitted IP addresses can be used to allow you access to hardcoded URLs.":
      "Para sistemas que não suportam nossa configuração QR, esses endereços IP permitidos podem ser usados ​​para permitir acesso a URLs codificados.",
    "No permitted IPs yet": "Nenhum IP permitido ainda",
    "Add another": "Adicionar outro",
    "Save changes": "Salvar alterações",
    "Permitted IP addresses updated": "Endereços IP permitidos atualizados",
    "Last updated": "Última atualização",
    "Clear template": "Limpar modelo",
    "No template selected": "Nenhum modelo selecionado",
  },
  DashboardScreen: { Dashboard: "Painel" },
  DevicesScreen: {
    Devices: "Dispositivos",
    Groups: "Grupos",
    "{{totalCount}} Devices": "{{totalCount}} Dispositivos",
    "New device": "Novo dispositivo",
    "Devices are the physical displays you have linked to your account.":
      "Dispositivos são os monitores físicos que você vinculou à sua conta.",
    "No devices have been setup yet":
      "Nenhum dispositivo foi configurado ainda",
    "No devices found for '{{searchText}}'":
      "Nenhum dispositivo encontrado para '{{searchText}}'",
    "Device name": "Nome do dispositivo",
    Facility: "Instalação",
    Configuration: "Configuração",
    "Facility not set": "Instalação não definida",
    "Config not set": "Configuração não definida",
    "Create device": "Criar dispositivo",
    "Update device": "Atualizar dispositivo",
    "Screen device updated": "Dispositivo de tela atualizado",
    "Something went wrong": "Algo deu errado",
    "Adding new display device": "Adicionando novo dispositivo de exibição",
    addNewDeviceDescription:
      "Para adicionar um novo dispositivo, visite <0>{{url}}</0> no dispositivo que deseja vincular.",
    "You will be shown a QR code which can be scanned by your camera.":
      "Será mostrado um código QR que pode ser escaneado pela sua câmera.",
    "Start scanning": "Comece a digitalizar",
    Ok: "OK",
    "Are you sure you want to delete this device?":
      "Tem certeza de que deseja excluir este dispositivo?",
    "Delete device": "Excluir dispositivo",
    "Fetching devices": "Buscando dispositivos",
    Timezone: "Fuso horário",
    "Display name is required": "O nome de exibição é obrigatório",
    "Display name must be at least 3 characters long":
      "O nome de exibição deve ter pelo menos três caracteres",
    "Device setup successful": "Configuração do dispositivo bem-sucedida",
    "Switch organisation": "Mudar de organização",
    "Search devices...": "Pesquisar dispositivos...",
    "Device not found": "Dispositivo não encontrado",
    "It may have been deleted, or you do not have permission to view it.":
      "Pode ter sido excluído ou você não tem permissão para visualizá-lo.",
    "Loading device": "Carregando dispositivo",
    "Last updated": "Última atualização",
    config: "Configuração",
    playlist: "Lista de reprodução",
    "Playlist not set": "Lista de reprodução não definida",
    "Device mode": "Modo de dispositivo",
    "Should this device display a configuration or a playlist?":
      "Este dispositivo deve exibir uma configuração ou uma lista de reprodução?",
  },
  NotFoundScreen: {
    "Oops! You seem to be lost.": "Ops! Você parece estar perdido.",
    "Return to dashboard": "Retornar ao painel",
  },
  ThemeScreen: {
    "New theme": "Novo tema",
    "Themes allow you to customise the colours of your configurations.":
      "Os temas permitem que você personalize as cores de suas configurações.",
    Themes: "Temas",
    "You don't have any themes yet": "Você ainda não tem temas",
    "Themes allow you to customise the colours used by your templates":
      "Os temas permitem que você personalize as cores usadas pelos seus modelos",
    "Create one now": "Create one now",
    "Theme name": "Nome do tema",
    "Last updated": "Última atualização",
    "Create theme": "Criar tema",
    "Update theme": "Atualizar tema",
    background: "Fundo",
    backgroundText: "Texto de fundo",
    accent: "Sotaque",
    accentText: "Texto de destaque",
    announcementBar: "Barra de anúncios",
    announcementBarText: "Texto da barra de anúncios",
    "Invalid hex code": "Código hexadecimal inválido",
    "New theme created": "Novo tema criado",
    "Theme updated": "Tema atualizado",
    "Screen theme deleted": "Tema da tela excluído",
    "Something went wrong": "Algo deu errado",
    "Are you sure you want to delete this theme?":
      "Tem certeza de que deseja excluir este tema?",
    "Delete theme": "Excluir tema",
    "Loading themes": "Carregando temas",
    "Upload logo": "Carregar logotipo",
    "Remove logo": "Remover logotipo",
    "Upload background image": "Carregar imagem de fundo",
    "Remove image": "Remover imagem",
    "Theme not found": "Tema não encontrado",
    "It may have been deleted, or you do not have permission to view it":
      "Pode ter sido excluído ou você não tem permissão para visualizá-lo",
    "Loading theme": "Carregando tema",
    "Search themes...": "Pesquisar temas...",
    "{{totalCount}} Themes": "{{totalCount}} Temas",
    "What's a theme?": "O que é um tema?",
    "Themes allow you to customise the appearance of your configurations":
      "Os temas permitem que você personalize a aparência de suas configurações",
    "Clicking this button will begin the process of creating a new one.":
      "Clicar neste botão iniciará o processo de criação de um novo.",
    "Give your theme a memorable name": "Dê ao seu tema um nome memorável",
    "This will help you find it later when assigning it to a configuration.":
      "Isso ajudará você a encontrá-lo mais tarde, ao atribuí-lo a uma configuração.",
    "Upload a logo (optional)": "Faça upload de um logotipo (opcional)",
    "If you do not upload a logo, a Teamworks logo will appear in its place.":
      "Se você não carregar um logotipo, um logotipo do Teamworks aparecerá em seu lugar.",
    "Upload a background image (optional)":
      "Carregue uma imagem de fundo (opcional)",
    "Some configurations make use of a background image. If an image is not uploaded, the background colour selected below will be used.":
      "Algumas configurações utilizam uma imagem de fundo. Se uma imagem não for carregada, será usada a cor de fundo selecionada abaixo.",
    "Customise the colours you want to use":
      "Personalize as cores que deseja usar",
    "The 'text' options should be a contrasting colour, to ensure it can be easily read.":
      "As opções de 'texto' devem ter uma cor contrastante, para garantir que possam ser facilmente lidas.",
    "Once you are ready, save the theme by clicking this button":
      "Quando estiver pronto, salve o tema clicando neste botão",
    "Your theme will then be available to be selected when managing a configuration.":
      "Seu tema estará então disponível para ser selecionado ao gerenciar uma configuração.",
    "Theme contrast": "Contraste do tema",
    "Some content appears in containers that are semi transparent and overlayed on your chosen background colour.":
      "Parte do conteúdo aparece em contêineres semitransparentes e sobrepostos à cor de fundo escolhida.",
    "Would you like to lighten or darken your background?":
      "Gostaria de clarear ou escurecer seu fundo?",
    Dark: "Escuro",
    Light: "Luz",
    "Theme preview": "Visualização do tema",
    Close: "Fechar",
    Preview: "Visualização",
  },
  ScreenTemplateNames: {
    "L shape with media": "Formato L com mídia",
    "Media only": "Somente mídia",
    "Schedule only": "Somente agendamento",
    "Multi day schedule": "Programação de vários dias",
    "Multi column schedule": "Programação de múltiplas colunas",
    Basic: "Básico",
  },
  DialogProvider: {
    Cancel: "Cancelar",
    Confirm: "Confirmar",
    Close: "Fechar",
    Ok: "OK",
    "Close without saving?": "Fechar sem salvar?",
    "Close without saving": "Fechar sem salvar",
    "Any unsaved changes will be lost":
      "Quaisquer alterações não salvas serão perdidas",
    "Back to form": "Voltar ao formulário",
  },
  ConfigurationPicker: {
    Configuration: "Configuração",
    "No configurations found for '{{searchText}}'":
      "Nenhuma configuração encontrada para '{{searchText}}'",
    "No configurations found": "Nenhuma configuração encontrada",
    "No configuration selected": "Nenhuma configuração selecionada",
    "Clear configuration": "Limpar configuração",
    "Searching configurations": "Pesquisando configurações",
  },
  ThemePicker: {
    Theme: "Tema",
    "Clear theme": "Limpar tema",
    "Screen theme": "Tema da tela",
    "No theme selected": "Nenhum tema selecionado",
    "No themes found matching '{{searchText}}'":
      "Nenhum tema encontrado correspondente a '{{searchText}}'",
    "No themes found": "Nenhum tema encontrado",
    "Loading themes...": "Carregando temas...",
  },
  MarkdownField: {
    Preview: "Visualização",
    Remove: "Remover",
    "Nothing to preview": "Nada para visualizar",
    Write: "Escrever",
  },
  ErrorScreen: {
    "Something went wrong": "Algo deu errado",
    "Sorry for the inconvience, our engineers have been notified and will be working on a fix":
      "Desculpe pela inconveniência, nossos engenheiros foram notificados e estarão trabalhando em uma correção",
    "If this issue persists, please contact Teamworks customer support":
      "Se o problema persistir, entre em contato com o suporte ao cliente do Teamworks",
    Reload: "Recarregar",
    "Customer support": "Suporte ao cliente",
  },
  OrganisationSwitcher: {
    "Fetching organisations": "Buscando organizações",
    "Select an organisation": "Selecione uma organização",
    "Switching profile": "Trocando perfil",
  },
  TimeZonePicker: {
    Timezone: "Fuso horário",
  },
  MatchesDrawer: {
    "Add match": "Adicionar correspondência",
    "Fetching event": "Buscando evento",
    "Event title": "Título do evento",
    "Starts at": "Começa às",
    "Create event": "Criar evento",
  },
  FileUploadField: {
    "Upload file": "Carregar arquivo",
    "Remove file": "Remover arquivo",
  },
  HubProfilePicker: {
    Search: "Procurar",
    "Clear search text": "Limpar texto de pesquisa",
    "Search text too short": "Texto de pesquisa muito curto",
    "Please add at least 3 characters to begin a search":
      "Adicione pelo menos 3 caracteres para iniciar uma pesquisa",
    "No calendars found": "Nenhuma agenda encontrada",
    "We couldn't find any calendars matching '{{searchText}}'":
      "Não encontramos nenhum calendário correspondente a '{{searchText}}'",
    "Search...": "Procurar...",
    "Confirm selection": "Confirmar seleção",
    Close: "Fechar",
    "Add Teamworks calendars": "Adicionar calendários Teamworks",
    "Loading profiles": "Carregando perfis",
    "Search text must have at least 3 characters":
      "O texto da pesquisa deve ter pelo menos 3 caracteres",
    "No results found": "Nenhum resultado encontrado",
    "Calendar details": "Detalhes do calendário",
    "Remove calendar": "Remover calendário",
    "Are you sure you want to remove this calendar?":
      "Tem certeza de que deseja remover esta agenda?",
    "No calendars added": "Nenhuma agenda adicionada",
    "Unknown profile": "Perfil desconhecido",
    "You don't have access to this hub profile":
      "Não tens acesso a este perfil do hub",
  },
  ScreenEventsPicker: {
    "Loading events": "Carregando eventos",
    "Event countdown": "Contagem regressiva do evento",
    "Create event": "Criar evento",
    "Update event": "Evento de atualização",
    "Event title": "Título do evento",
    "Starts at": "Começa às",
    "Add opponent badge": "Adicionar emblema do oponente",
    "Remove opponent badge": "Remover distintivo do oponente",
    "Delete event": "Excluir evento",
    "Are you sure you want to delete this event?":
      "Tem certeza de que deseja excluir este evento?",
    "Delete now": "Excluir agora",
    "Opponent badge position": "Posição do distintivo do oponente",
    "Should the opponent badge be on the left or right?":
      "O distintivo do adversário deve ficar à esquerda ou à direita?",
    "i.e are you playing home or away?":
      "ou seja, você está jogando em casa ou fora?",
    "Edit event": "Editar evento",
  },
  ScreenConfigDrawer: {
    "Loading configuration": "Carregando configuração",
    "Number of days the schedule should span. Max 5":
      "Número de dias que a programação deve abranger. Máximo 5",
    "Number of days": "Número de dias",
    "Delete configuration": "Excluir configuração",
    "Are you sure you want to delete this configuration?":
      "Tem certeza de que deseja excluir esta configuração?",
    "Configuration deleted": "Configuração excluída",
    "Something went wrong": "Algo deu errado",
    "Save changes": "Salvar alterações",
    "Configuration updated": "Configuração atualizada",
    bottom_content_cards: "Cartões",
    bottom_content_cards_desc:
      "Selecione cartões para adicionar texto ou imagens",
    media_content: "Mídia",
    media_content_desc: "Selecione entre vídeos, imagens e conteúdo do YouTube",
    multi_column_schedule: "Programação de múltiplas colunas",
    multi_column_schedule_desc:
      "Selecione calendários de trabalho em equipe para exibir na tela",
    multi_day_schedule: "Programação de vários dias",
    multi_day_schedule_desc:
      "Selecione calendários de trabalho em equipe para exibir na tela",
    right_schedule: "Agendar",
    right_schedule_desc:
      "Selecione calendários de trabalho em equipe para exibir na tela",
    "%future added value": "Componente desconhecido",
    "%future added value_desc": "Componente desconhecido",
    "Configuration name": "Nome da configuração",
    "Add event countdown": "Adicionar contagem regressiva de eventos",
    "A countdown will be displayed until the event starts":
      "Uma contagem regressiva será exibida até o início do evento",
    "Not saved": "Não salvo",
    "Please fix any errors and try again":
      "Corrija quaisquer erros e tente novamente",
    "Give the configuration a name": "Dê um nome à configuração",
    "Try to give it a relevant name, to help you quickly find it":
      "Tente dar um nome relevante para ajudá-lo a encontrá-lo rapidamente",
    "Pick a theme": "Escolha um tema",
    "The theme will set colours and logo for your configuration":
      "O tema definirá cores e logotipo para sua configuração",
    "Add an event (optional)": "Adicione um evento (opcional)",
    "A countdown will be displayed on the configuration until the event starts":
      "Uma contagem regressiva será exibida na configuração até o início do evento",
    "Add a Teamworks schedule": "Adicionar uma agenda do Teamworks",
    "Upload media": "Carregar mídia",
    "Add cards (optional)": "Adicionar cartões (opcional)",
    "Add multiple Teamworks schedules":
      "Adicione vários agendamentos do Teamworks",
    "Unknown component": "Componente desconhecido",
    "Events from the selected profiles will be shown on the configuration":
      "Os eventos dos perfis selecionados serão mostrados na configuração",
    "You can upload images, videos or add YouTube videos":
      "Você pode fazer upload de imagens, vídeos ou adicionar vídeos do YouTube",
    "The configuration will rotate through the cards one at a time":
      "A configuração irá girar através dos cartões, um de cada vez",
    "Each schedule will have its own column on the configuration":
      "Cada agendamento terá sua própria coluna na configuração",
    "A multiday schedule will display the events from the selected profiles across multiple days":
      "Uma programação de vários dias exibirá os eventos dos perfis selecionados em vários dias",
    "Save the configuration": "Salve a configuração",
    "Once you are happy with your updates, save the changes":
      "Quando estiver satisfeito com suas atualizações, faça o mesmo com as alterações",
    "Take a tour": "Faça um tour",
  },
  AnnouncementsScreen: {
    "Announcement text is required": "O texto do anúncio é obrigatório",
    "Announcements updated": "Anúncios atualizados",
    "Something went wrong": "Algo deu errado",
    "Announcements allow you to post important information to be displayed in banners across all devices.":
      "Os anúncios permitem que você publique informações importantes para serem exibidas em banners em todos os dispositivos.",
    Announcements: "Anúncios",
    "Loading announcements": "Carregando anúncios",
    "Manage announcements": "Gerenciar anúncios",
    "Announcements are displayed on all screens, at all times":
      "Os anúncios são exibidos em todas as telas, em todos os momentos",
    "No announcements yet": "Nenhum anúncio ainda",
    "New announcement": "Novo anúncio",
    "Save announcement": "Salvar anúncio",
    "Note: announcements must be a single line. New lines will be ignored.":
      "Observação: os anúncios devem ter uma única linha. Novas linhas serão ignoradas.",
    "Delete announcement": "Excluir anúncio",
    "Are you sure you want to remove this annoucement?":
      "Tem certeza de que deseja remover este anúncio?",
    "{{totalCount}} Announcements": "{{totalCount}} Anúncios",
    Content: "Contente",
    "Last updated": "Última atualização",
  },
  CardsFormField: {
    "Add card": "Adicionar cartão",
    "Add image": "Adicionar cartão",
    "Remove image": "Remover imagem",
    "Remove card": "Remover cartão",
    "Are you sure you want to remove this card?":
      "Tem certeza de que deseja remover este cartão?",
    "No cards added": "Nenhum cartão adicionado",
  },
  MediaFormField: {
    "Invalid YouTube link, please try again":
      "Link do YouTube inválido. Tente novamente",
    "File not supported, please upload an image or video":
      "Arquivo não suportado, envie uma imagem ou vídeo",
    "Upload image/video": "Carregar imagem/vídeo",
    "Add YouTube": "Adicionar YouTube",
    "Add YouTube video": "Adicionar vídeo do YouTube",
    "Video title": "Título do vídeo",
    "YouTube link": "Link do YouTube",
    Image: "Imagem",
    Video: "Video",
    YouTube: "YouTube",
    "Something went wrong, file not uploaded":
      "Algo deu errado, o arquivo não foi enviado",
    "Cancel file upload?": "Cancelar o upload do arquivo?",
    "Are you sure you want to cancel this upload?":
      "Tem certeza de que deseja cancelar este upload?",
    "Upload image": "Carregar imagem",
    "Upload video": "Enviar vídeo",
    "Uploading {{uploadPct}}%": "Carregando {{uploadPct}}%",
    "Upload complete": "Carregamento concluído",
    Name: "Nome",
    "Number seconds image displays before moving to next media item":
      "Número de segundos que a imagem é exibida antes de passar para o próximo item de mídia",
    "Duration (seconds)": "Duração (segundos)",
    "Add image": "Adicionar imagem",
    "Add video": "Adicionar vídeo",
    "Delete media": "Excluir mídia",
    "Are you sure you want to delete this media?":
      "Tem certeza de que deseja excluir esta mídia?",
    "Update duration": "Duração da atualização",
    "Edit duration": "Editar duração",
    "Remove media": "Remover mídia",
    "No media added": "Nenhuma mídia adicionada",
  },
  MultiColumnScheduleFormField: {
    "Add column": "Adicionar coluna",
    "Column title": "Título da coluna",
    "Remove column": "Remover coluna",
    "Are you sure you want to remove this column?":
      "Tem certeza de que deseja remover esta coluna?",
  },
  AuthProvider: {
    "Fetching active users": "Buscando usuários ativos",
    "Setting organisations": "Configurando organizações",
    "Logging you in": "Fazendo login",
  },
  AccessDeniedScreen: {
    "You don't have access to Digital Displays":
      "Você não tem acesso a displays digitais",
    "Please contact Teamworks customer support to request access":
      "Entre em contato com o suporte ao cliente Teamworks para solicitar acesso",
    "Sign out": "Sair",
    "Customer support": "Suporte ao cliente",
    Reload: "Recarregar",
  },
  DeviceGroupsTab: {
    "Delete group": "Excluir grupo",
    "Are you sure you want to delete this group?":
      "Tem certeza de que deseja excluir este grupo?",
    "Device group deleted successfully":
      "Grupo de dispositivos excluído com sucesso",
    "Something went wrong": "Algo deu errado",
    "Device group created successfully":
      "Grupo de dispositivos criado com sucesso",
    "Group updated": "Grupo atualizado",
    "New group": "Novo grupo",
    "Device groups allow you to group your display devices under one name.":
      "Os grupos de dispositivos permitem agrupar seus dispositivos de exibição sob um nome.",
    "Device groups": "Grupos de dispositivos",
    "{{totalCount}} Device groups": "{{totalCount}} grupos de dispositivos",
    "Search groups...": "Pesquisar grupos...",
    "Group name": "Nome do grupo",
    "Device count": "Contagem de dispositivos",
    "Fetching groups": "Buscando grupos",
    "No groups": "Nenhum grupo",
    "Group name is required": "O nome do grupo é obrigatório",
    "Please select at least 2 devices":
      "Selecione pelo menos dois dispositivos",
    "Create group": "Criar grupo",
    "Update group": "Atualizar grupo",
    "Fetching devices...": "Buscando dispositivos...",
    "Select devices": "Selecione dispositivos",
    Devices: "Dispositivos",
    Groups: "Grupos",
  },
  ErrorState: {
    "Something went wrong": "Algo deu errado",
    "Please try again, or contact Teamworks support if the issue persists":
      "Tente novamente ou entre em contato com o suporte do Teamworks se o problema persistir",
  },
  ProductToursProvider: {
    Back: "Voltar",
    Next: "Próximo",
    Finish: "Terminar",
  },
  Drawer: {
    Close: "Fechar",
  },
  InitialSearchParamsProvider: {
    "Initializing URL params": "Inicializando parâmetros de URL",
  },
  PreviewsProvider: {
    "Redirecting to preview": "Redirecionando para visualização",
  },
  PlaylistsScreen: {
    Playlists: "Listas de reprodução",
    "New playlist": "Nova lista de reprodução",
    "{{totalCount}} Playlists": "{{totalCount}} listas de reprodução",
    Name: "Nome",
    "Loading playlists": "Carregando listas de reprodução",
    "No playlists have been setup yet":
      "Nenhuma playlist foi configurada ainda",
    "Playlists allow you to join multiple configurations together.":
      "As listas de reprodução permitem que você junte várias configurações.",
    "No configurations selected": "Nenhuma configuração selecionada",
    "Add configuration": "Adicionar configuração",
    Cancel: "Cancelar",
    "Create playlist": "Criar lista de reprodução",
    "Delete playlist": "Excluir lista de reprodução",
    "Are you sure you want to delete this playlist?":
      "Tem certeza de que deseja excluir esta playlist?",
    "Loading playlist": "Carregando lista de reprodução",
    "Save changes": "Save changes",
    "Configuration already in playlist": "Configuração já na playlist",
    "The selected configuration is already part of this playlist, do you want to add it again?":
      "A configuração selecionada já faz parte desta playlist, deseja adicioná-la novamente?",
    "Remove from playlist": "Remover da lista de reprodução",
    Configuration: "Configuração",
    "Number loops": "Loops numéricos",
    Active: "Ativo",
    "Playlist updated": "Lista de reprodução atualizada",
    "Something went wrong": "Algo deu errado",
    "Last updated": "Última atualização",
    "Digital Displays URL": "URL de monitores digitais",
    "You may want to use this hardcoded url to integrate with systems that do not support our QR code setup.":
      "Você pode querer usar este URL codificado para integração com sistemas que não suportam nossa configuração de código QR.",
    "Get hardcoded URL": "Obtenha URL codificado",
    l_layout_loop_description:
      "Os itens de mídia farão um loop um determinado número de vezes antes de mudar para a próxima configuração",
    media_only_loop_description:
      "Os itens de mídia farão um loop um determinado número de vezes antes de mudar para a próxima configuração",
    basic_loop_description:
      "O agendamento irá repetir um determinado número de vezes antes de mudar para a próxima configuração",
    multi_column_schedule_loop_description:
      "O agendamento irá repetir um determinado número de vezes antes de mudar para a próxima configuração",
    multi_day_schedule_loop_description:
      "O agendamento irá repetir um determinado número de vezes antes de mudar para a próxima configuração",
    schedule_only_loop_description:
      "O agendamento irá repetir um determinado número de vezes antes de mudar para a próxima configuração",
    "%future added value_loop_description": "Tipo de configuração desconhecido",
    mvp_loop_description: "Tipo de configuração desconhecido",
    timed_description:
      "A configuração permanecerá na tela por um determinado período de tempo antes de mudar para a próxima configuração",
    Looped: "Em loop",
    Timed: "Cronometrado",
    "Duration (minutes)": "Duração (minutos)",
    "Edit configuration": "Editar configuração",
    "New configuration": "Nova configuração",
    "Loading configuration": "A carregar configuração",
    "Add an existing configuration to the playlist":
      "Adicionar uma configuração existente à lista de reprodução",
    "Create a new configuration to add to the playlist":
      "Criar uma nova configuração para adicionar à lista de reprodução",
  },
  PlaylistPicker: {
    Playlist: "Lista de reprodução",
    "Clear playlist": "Limpar lista de reprodução",
    "No playlist selected": "Nenhuma playlist selecionada",
    "Searching playlists": "Pesquisando listas de reprodução",
    "No playlists found for '{{searchText}}'":
      "Nenhuma playlist encontrada para '{{searchText}}'",
    "No playlists found": "Nenhuma playlist encontrada",
  },
};
