/**
 * @generated SignedSource<<ff1c4182c514c4b2fa1db05383288126>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AuthProviderQuery$variables = Record<PropertyKey, never>;
export type AuthProviderQuery$data = {
  readonly activeUsers: {
    readonly nodes: ReadonlyArray<{
      readonly email: string;
      readonly firstName: string;
      readonly fullName: string;
      readonly hubId: string;
      readonly id: string;
      readonly lastName: string;
      readonly organisations: {
        readonly nodes: ReadonlyArray<{
          readonly hubId: string;
          readonly id: string;
          readonly name: string;
        }>;
      };
    }>;
  };
};
export type AuthProviderQuery = {
  response: AuthProviderQuery$data;
  variables: AuthProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hubId",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserConnection",
    "kind": "LinkedField",
    "name": "activeUsers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganisationConnection",
            "kind": "LinkedField",
            "name": "organisations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organisation",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthProviderQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AuthProviderQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "f8cf5f75ba8af7aa9ce69ecfe542420f",
    "id": null,
    "metadata": {},
    "name": "AuthProviderQuery",
    "operationKind": "query",
    "text": "query AuthProviderQuery {\n  activeUsers {\n    nodes {\n      id\n      hubId\n      fullName\n      firstName\n      email\n      lastName\n      organisations {\n        nodes {\n          id\n          hubId\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e98105fee60582cd6d352f51e912ceb2";

export default node;
