import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import Joyride from "react-joyride";
import { useNavigate } from "react-router-dom";
import { Column, Row } from "../components/Flex";
import { borderRadius, colors } from "../helpers/theme";
import { Button } from "../components/Button";
import { Divider } from "@mui/material";
import { defer } from "lodash";
import { useDebounced } from "../hooks/useDebounced";
import { AppText, HelperText } from "../components/Typography";
import { useScreenSize } from "./ScreenSizeProvider";
import { useTranslation } from "react-i18next";

export type ProductTourStep = {
  target: string;
  title: string;
  body: string;
  path?: string;
};

type ProductTourContextType = {
  startTour: (steps: ProductTourStep[]) => void;
};

type ProductTourState =
  | {
      tag: "idle";
    }
  | {
      tag: "active";
      stepIndex: number;
      steps: ProductTourStep[];
      loading: false | "next" | "prev";
    };

const ProductTourContext = createContext<ProductTourContextType>(null!);

const onTargetReady = (
  target: string,
  callback: () => void,
  retryCount?: number,
) => {
  if (retryCount != null && retryCount >= 10) {
    throw new Error(
      `ProductTourProvider: target not found after 10 retries: ${target}`,
    );
  }

  if (document.getElementById(target.replace("#", "")) != null) {
    callback();
  } else {
    setTimeout(
      () => onTargetReady(target, callback, (retryCount || 0) + 1),
      retryCount == null ? 300 : 100,
    );
  }
};

export const ProductToursProvider = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation("ProductToursProvider");
  const navigate = useNavigate();
  const screenSize = useScreenSize();
  const [state, setState] = useState<ProductTourState>({ tag: "idle" });

  useEffect(() => setState({ tag: "idle" }), [screenSize]);

  const moveToStep = (stepIndex: number) => {
    if (state.tag !== "active") {
      throw new Error("tried to move step while not active");
    }

    const step = state.steps[stepIndex];

    if (step == null) {
      return setState({ tag: "idle" });
    }

    if (step.path != null) navigate(step.path);
    onTargetReady(step.target, () =>
      setState((s) => ({ ...s, stepIndex, loading: false })),
    );
  };

  const isLoadingPrev = useDebounced(
    state.tag === "active" && state.loading === "prev",
    50,
  );

  const isLoadingNext = useDebounced(
    state.tag === "active" && state.loading === "next",
    50,
  );

  return (
    <ProductTourContext.Provider
      value={{
        startTour: (steps) => {
          setState({ tag: "active", steps, stepIndex: 0, loading: false });
        },
      }}
    >
      {children}

      <Joyride
        disableScrollParentFix
        styles={{ options: { zIndex: 999999 } }}
        continuous
        run={state.tag === "active"}
        stepIndex={state.tag === "active" ? state.stepIndex : 0}
        steps={
          state.tag !== "active"
            ? undefined
            : state.steps.map((s) => ({
                target: s.target,
                content: (
                  <Column gap="smallest">
                    <AppText>{s.title}</AppText>
                    <HelperText>{s.body}</HelperText>
                  </Column>
                ),
                disableBeacon: true,
              }))
        }
        callback={({ action, lifecycle, type }) => {
          if (state.tag !== "active") return;

          const hasEnded =
            (action === "close" && lifecycle === "complete") ||
            type === "tour:end";

          if (hasEnded) {
            setState({ tag: "idle" });
          } else if (lifecycle === "complete") {
            setState((s) => ({
              ...s,
              loading: action === "prev" ? "prev" : "next",
            }));
            defer(() =>
              moveToStep(state.stepIndex + (action === "prev" ? -1 : 1)),
            );
          }
        }}
        tooltipComponent={(props) => (
          <Column
            style={{
              background: colors.white,
              borderRadius: borderRadius.regular,
              overflow: "hidden",
              minWidth: 300,
              maxWidth: screenSize === "sm" ? "95vw" : 600,
            }}
          >
            <Column padding="medium">{props.step.content}</Column>
            <Divider />
            <Row
              padding="small"
              justifyContent="space-between"
              alignItems="center"
              backgroundColor={colors.gray1}
            >
              <Button
                size="medium"
                variant="text"
                onClick={props.backProps.onClick}
                loading={isLoadingPrev}
                disabled={props.index === 0}
              >
                {t("Back")}
              </Button>
              <HelperText>
                {props.index + 1} / {props.size}
              </HelperText>
              <Button
                size="medium"
                onClick={props.primaryProps.onClick}
                loading={isLoadingNext}
              >
                {t(props.isLastStep ? "Finish" : "Next")}
              </Button>
            </Row>
          </Column>
        )}
      />
    </ProductTourContext.Provider>
  );
};

export const useStartTour = () => {
  const ctx = useContext(ProductTourContext);
  if (ctx == null) {
    throw new Error("useStartTour called before ready");
  }

  return ctx.startTour;
};
