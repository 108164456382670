import React, { DOMAttributes, PropsWithChildren, forwardRef } from "react";
import {
  borderRadius,
  colors,
  fontSize,
  iconSize,
  spacing,
  toRgba,
} from "../helpers/theme";
import { makeStyles } from "@mui/styles";
import { MuiIcon, SvgProps } from "../helpers/types";
import classNames from "classnames";
import { LoadingSpinner } from "./LoadingSpinner";

export type ButtonProps = PropsWithChildren & {
  id?: string;
  onClick?: DOMAttributes<HTMLButtonElement>["onClick"];
  variant?: "default" | "secondary" | "text";
  color?: "default" | "destructive";
  size?: "large" | "medium" | "small";
  loading?: boolean;
  disabled?: boolean;
  startIcon?: MuiIcon | ((props: SvgProps) => JSX.Element);
  endIcon?: MuiIcon;
  autoFocus?: boolean;
};

const useStyles = makeStyles({
  base: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: borderRadius.regular,
    cursor: "pointer",
    gap: spacing.smallest,
    boxSizing: "border-box",
    fontWeight: 600,
    border: 0,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const useLargeStyles = makeStyles({
  button: {
    height: 44,
    fontSize: fontSize.body,
    padding: `0 ${spacing.small}px`,
  },
  icon: {
    width: `${iconSize.medium}px !important`,
    height: `${iconSize.medium}px !important`,
  },
});

type ButtonSizeStyles = typeof useLargeStyles;

const useMediumStyles: ButtonSizeStyles = makeStyles({
  button: {
    height: 36,
    fontSize: fontSize.small,
    padding: `0 ${spacing.smaller}px`,
  },
  icon: {
    width: `${iconSize.regular}px !important`,
    height: `${iconSize.regular}px !important`,
  },
});

const useSmallStyles: ButtonSizeStyles = makeStyles({
  button: {
    height: 28,
    fontSize: fontSize.detail,
    padding: `0 ${spacing.smallest}px`,
  },
  icon: {
    width: `${iconSize.small}px !important`,
    height: `${iconSize.small}px !important`,
  },
});

const useDefaultStyles = makeStyles({
  button: {
    background: colors.black,
    color: colors.white,
  },
  disabled: {
    background: colors.gray5,
    cursor: "not-allowed",
  },
  destructive: {
    background: colors.red3,
    color: colors.white,
  },
});

type ButtonVariantStyles = typeof useDefaultStyles;

const useSecondaryStyles: ButtonVariantStyles = makeStyles({
  button: {
    background: colors.white,
    border: `1px solid ${toRgba(colors.black, 0.3)}`,
    color: colors.black,
    "&:hover": {
      filter: `drop-shadow(0px 3px 3px ${toRgba(colors.black, 0.1)})`,
    },
  },
  destructive: {
    borderColor: colors.red2,
    color: colors.red3,
    "&:hover": {
      filter: `drop-shadow(0px 3px 3px ${toRgba(colors.black, 0.1)})`,
      backgroundColor: colors.red1,
    },
  },
  disabled: {
    color: colors.gray5,
    borderColor: colors.gray5,
    cursor: "not-allowed",
  },
});

const useTextStyles: ButtonVariantStyles = makeStyles({
  button: {
    background: colors.white,
    color: colors.black,
  },
  disabled: {
    color: colors.gray4,
    cursor: "not-allowed",
  },
  destructive: {
    color: colors.red3,
  },
});

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(props, ref) {
    const variant = props.variant ?? "default";
    const size = props.size ?? "large";
    const color = props.color ?? "default";

    const { base } = useStyles(props);

    const sizeClasses =
      size === "large"
        ? useLargeStyles()
        : size === "medium"
          ? useMediumStyles()
          : useSmallStyles();

    const variantClasses =
      variant === "default"
        ? useDefaultStyles()
        : variant === "secondary"
          ? useSecondaryStyles()
          : useTextStyles();

    return (
      <button
        id={props.id}
        ref={ref}
        autoFocus={props.autoFocus}
        onClick={(ev) => {
          if (props.disabled || props.loading) return;
          props.onClick?.(ev);
        }}
        className={classNames(
          base,
          sizeClasses.button,
          variantClasses.button,
          props.disabled ? variantClasses.disabled : undefined,
          color === "destructive" ? variantClasses.destructive : undefined,
        )}
      >
        {!props.loading && props.startIcon != null && (
          <props.startIcon className={sizeClasses.icon} />
        )}
        {props.loading && <LoadingSpinner size={size} />}
        {props.children}
        {props.endIcon != null && (
          <props.endIcon className={sizeClasses.icon} />
        )}
      </button>
    );
  },
);
