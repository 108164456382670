export const english = {
  dateFormats: {
    datePickerLocale: "en-gb",
    timeFormat: "HH:mm", // 14:30
    dayMonthYearFormat: "do MMMM yyyy", // 11th January 1988
    dayMonthYearTimeFormat: "do MMMM yyyy, HH:mm", // 11th January 1988, 14:30
  },
  App: {
    "Digital Displays": "Digital Displays",
    Loading: "Loading",
    "Checking authentication": "Checking authentication",
    "Redirecting to login": "Redirecting to login",
    "Sign in with Teamworks": "Sign in with Teamworks",
    Initalizing: "Initalizing",
    "Verifing authentication": "Verifing authentication",
    Redirecting: "Redirecting",
    "Welcome back!": "Welcome back!",
    "Login to get started": "Login to get started",
  },
  AppSwitcher: {
    arms: "Compliance + Recruiting",
    hub: "Hub",
    inflcr: "Influencer",
    academics: "Academics",
    communities: "Communities",
    pulse: "Pulse",
    pathways: "Pathways",
    nutrition: "Nutrition",
    ams: "AMS",
    tv: "Digital Displays",
    trac: "Inventory Management",
    strength: "Strength + Conditioning",
    wallet: "Wallet",
  },
  Layout: {
    "Checking authentication": "Checking authentication",
    Dashboard: "Dashboard",
    Configurations: "Configurations",
    Devices: "Devices",
    Themes: "Themes",
    Settings: "Settings",
    Announcements: "Announcements",
    "Teamworks Help": "Teamworks Help",
    "Teamworks Apps": "Teamworks Apps",
    "Privacy policy": "Privacy policy",
    Logout: "Logout",
    "Loading, please wait...": "Loading, please wait...",
    Users: "Users",
    "Switch organisation": "Switch organisation",
    "Signing you out": "Signing you out",
    "Open Menu": "Open Menu",
    "Close Menu": "Close Menu",
    Playlists: "Playlists",
  },
  ConfigsScreen: {
    "{{totalCount}} Configurations": "{{totalCount}} Configurations",
    "New configuration": "New configuration",
    "Configurations allow you to customise the look and content of your displays.":
      "Configurations allow you to customise the look and content of your displays.",
    "Loading configurations": "Loading configurations",
    Configurations: "Configurations",
    Name: "Name",
    Template: "Template",
    Theme: "Theme",
    "Device count": "Device count",
    "Created at": "Created at",
    "No configurations have been setup yet":
      "No configurations have been setup yet",
    "No configurations found for '{{searchText}}'":
      "No configurations found for '{{searchText}}'",
    "Displays allow you to cast scheduled events and media to any device with an internet browser":
      "Displays allow you to cast scheduled events and media to any device with an internet browser",
    "Create one now": "Create one now",
    "Create configuration": "Create configuration",
    "Add custom videos, YouTube videos and images":
      "Add custom videos, YouTube videos and images",
    "Filter calendar to present a live schedule":
      "Filter calendar to present a live schedule",
    "Cycle through important updates on cards":
      "Cycle through important updates on cards",
    "Includes team crest, time and date": "Includes team crest, time and date",
    "Display a schedule over multiple days":
      "Display a schedule over multiple days",
    "Display daily schedule for different teams and groups.":
      "Display daily schedule for different teams and groups.",
    "Configuration deleted": "Configuration deleted",
    "Are you sure you want to delete this configuration?":
      "Are you sure you want to delete this configuration?",
    "Delete configuration": "Delete configuration",
    "Fetching configuration": "Fetching configuration",
    "Digital Displays URL": "Digital Displays URL",
    "You may want to use this hardcoded url to integrate with systems that do not support our QR code setup.":
      "You may want to use this hardcoded url to integrate with systems that do not support our QR code setup.",
    "Please speak with your Teamworks administrator to ensure your IP addresses are permitted to access the link for security.":
      "Please speak with your Teamworks administrator to ensure your IP addresses are permitted to access the link for security.",
    "Something went wrong": "Something went wrong",
    "Edit configuration": "Edit configuration",
    "Get hardcoded URL": "Get hardcoded URL",
    "Includes background image from theme":
      "Includes background image from theme",
    "Last active at {{lastActiveAt}}": "Last active at {{lastActiveAt}}",
    "Loading screen devices": "Loading screen devices",
    "Search configurations...": "Search configurations...",
    "Manage IP addresses": "Manage IP addresses",
    "For systems which do not support our QR setup, these permitted IP addresses can be used to allow you access to hardcoded URLs.":
      "For systems which do not support our QR setup, these permitted IP addresses can be used to allow you access to hardcoded URLs.",
    "No permitted IPs yet": "No permitted IPs yet",
    "Add another": "Add another",
    "Save changes": "Save changes",
    "Permitted IP addresses updated": "Permitted IP addresses updated",
    "Last updated": "Last updated",
    "Clear template": "Clear template",
    "No template selected": "No template selected",
  },
  DashboardScreen: { Dashboard: "Dashboard" },
  DevicesScreen: {
    Devices: "Devices",
    Groups: "Groups",
    "{{totalCount}} Devices": "{{totalCount}} Devices",
    "New device": "New device",
    "Devices are the physical displays you have linked to your account.":
      "Devices are the physical displays you have linked to your account.",
    "No devices have been setup yet": "No devices have been setup yet",
    "No devices found for '{{searchText}}'":
      "No devices found for '{{searchText}}'",
    "Device name": "Device name",
    Facility: "Facility",
    Configuration: "Configuration",
    "Facility not set": "Facility not set",
    "Config not set": "Config not set",
    "Create device": "Create device",
    "Update device": "Update device",
    "Screen device updated": "Screen device updated",
    "Something went wrong": "Something went wrong",
    "Adding new display device": "Adding new display device",
    addNewDeviceDescription:
      "To add a new device, visit <0>{{url}}</0> on the device you want to link.",
    "You will be shown a QR code which can be scanned by your camera.":
      "You will be shown a QR code which can be scanned by your camera.",
    "Start scanning": "Start scanning",
    Ok: "Ok",
    "Are you sure you want to delete this device?":
      "Are you sure you want to delete this device?",
    "Delete device": "Delete device",
    "Fetching devices": "Fetching devices",
    Timezone: "Timezone",
    "Display name is required": "Display name is required",
    "Display name must be at least 3 characters long":
      "Display name must be at least 3 characters long",
    "Device setup successful": "Device setup successful",
    "Switch organisation": "Switch organisation",
    "Search devices...": "Search devices...",
    "Device not found": "Device not found",
    "It may have been deleted, or you do not have permission to view it.":
      "It may have been deleted, or you do not have permission to view it.",
    "Loading device": "Loading device",
    "Last updated": "Last updated",
    config: "Configuration",
    playlist: "Playlist",
    "Playlist not set": "Playlist not set",
    "Device mode": "Device mode",
    "Should this device display a configuration or a playlist?":
      "Should this device display a configuration or a playlist?",
  },
  NotFoundScreen: {
    "Oops! You seem to be lost.": "Oops! You seem to be lost.",
    "Return to dashboard": "Return to dashboard",
  },
  ThemeScreen: {
    "New theme": "New theme",
    "Themes allow you to customise the colours of your configurations.":
      "Themes allow you to customise the colours of your configurations.",
    Themes: "Themes",
    "You don't have any themes yet": "You don't have any themes yet",
    "Themes allow you to customise the colours used by your templates":
      "Themes allow you to customise the colours used by your templates",
    "Create one now": "Create one now",
    "Theme name": "Theme name",
    "Last updated": "Last updated",
    "Create theme": "Create theme",
    "Update theme": "Update theme",
    background: "Background",
    backgroundText: "Background text",
    accent: "Accent",
    accentText: "Accent text",
    announcementBar: "Announcement bar",
    announcementBarText: "Announcement bar text",
    "Invalid hex code": "Invalid hex code",
    "New theme created": "New theme created",
    "Theme updated": "Theme updated",
    "Screen theme deleted": "Screen theme deleted",
    "Something went wrong": "Something went wrong",
    "Are you sure you want to delete this theme?":
      "Are you sure you want to delete this theme?",
    "Delete theme": "Delete theme",
    "Loading themes": "Loading themes",
    "Upload logo": "Upload logo",
    "Remove logo": "Remove logo",
    "Upload background image": "Upload background image",
    "Remove image": "Remove image",
    "Theme not found": "Theme not found",
    "It may have been deleted, or you do not have permission to view it":
      "It may have been deleted, or you do not have permission to view it",
    "Loading theme": "Loading theme",
    "Search themes...": "Search themes...",
    "{{totalCount}} Themes": "{{totalCount}} Themes",
    "What's a theme?": "What's a theme?",
    "Themes allow you to customise the appearance of your configurations":
      "Themes allow you to customise the appearance of your configurations",
    "Clicking this button will begin the process of creating a new one.":
      "Clicking this button will begin the process of creating a new one.",
    "Give your theme a memorable name": "Give your theme a memorable name",
    "This will help you find it later when assigning it to a configuration.":
      "This will help you find it later when assigning it to a configuration.",
    "Upload a logo (optional)": "Upload a logo (optional)",
    "If you do not upload a logo, a Teamworks logo will appear in its place.":
      "If you do not upload a logo, a Teamworks logo will appear in its place.",
    "Upload a background image (optional)":
      "Upload a background image (optional)",
    "Some configurations make use of a background image. If an image is not uploaded, the background colour selected below will be used.":
      "Some configurations make use of a background image. If an image is not uploaded, the background colour selected below will be used.",
    "Customise the colours you want to use":
      "Customise the colours you want to use",
    "The 'text' options should be a contrasting colour, to ensure it can be easily read.":
      "The 'text' options should be a contrasting colour, to ensure it can be easily read.",
    "Once you are ready, save the theme by clicking this button":
      "Once you are ready, save the theme by clicking this button",
    "Your theme will then be available to be selected when managing a configuration.":
      "Your theme will then be available to be selected when managing a configuration.",
    "Theme contrast": "Theme contrast",
    "Some content appears in containers that are semi transparent and overlayed on your chosen background colour.":
      "Some content appears in containers that are semi transparent and overlayed on your chosen background colour.",
    "Would you like to lighten or darken your background?":
      "Would you like to lighten or darken your background?",
    Dark: "Dark",
    Light: "Light",
    "Theme preview": "Theme preview",
    Close: "Close",
    Preview: "Preview",
  },
  ScreenTemplateNames: {
    "L shape with media": "L shape with media",
    "Media only": "Media only",
    "Schedule only": "Schedule only",
    "Multi day schedule": "Multi day schedule",
    "Multi column schedule": "Multi column schedule",
    Basic: "Basic",
  },
  DialogProvider: {
    Cancel: "Cancel",
    Confirm: "Confirm",
    Close: "Close",
    Ok: "Ok",
    "Close without saving?": "Close without saving?",
    "Close without saving": "Close without saving",
    "Any unsaved changes will be lost": "Any unsaved changes will be lost",
    "Back to form": "Back to form",
  },
  ConfigurationPicker: {
    Configuration: "Configuration",
    "No configurations found for '{{searchText}}'":
      "No configurations found for '{{searchText}}'",
    "No configurations found": "No configurations found",
    "No configuration selected": "No configuration selected",
    "Clear configuration": "Clear configuration",
    "Searching configurations": "Searching configurations",
  },
  ThemePicker: {
    Theme: "Theme",
    "Clear theme": "Clear theme",
    "Screen theme": "Screen theme",
    "No theme selected": "No theme selected",
    "No themes found matching '{{searchText}}'":
      "No themes found matching '{{searchText}}'",
    "No themes found": "No themes found",
    "Loading themes...": "Loading themes...",
  },
  MarkdownField: {
    Preview: "Preview",
    Remove: "Remove",
    "Nothing to preview": "Nothing to preview",
    Write: "Write",
  },
  ErrorScreen: {
    "Something went wrong": "Something went wrong",
    "Sorry for the inconvience, our engineers have been notified and will be working on a fix":
      "Sorry for the inconvience, our engineers have been notified and will be working on a fix",
    "If this issue persists, please contact Teamworks customer support":
      "If this issue persists, please contact Teamworks customer support",
    Reload: "Reload",
    "Customer support": "Customer support",
  },
  OrganisationSwitcher: {
    "Fetching organisations": "Fetching organisations",
    "Select an organisation": "Select an organisation",
    "Switching profile": "Switching profile",
  },
  TimeZonePicker: {
    Timezone: "Timezone",
  },
  MatchesDrawer: {
    "Add match": "Add match",
    "Fetching event": "Fetching event",
    "Event title": "Event title",
    "Starts at": "Starts at",
    "Create event": "Create event",
  },
  FileUploadField: {
    "Upload file": "Upload file",
    "Remove file": "Remove file",
  },
  HubProfilePicker: {
    Search: "Search",
    "Clear search text": "Clear search text",
    "Search text too short": "Search text too short",
    "Please add at least 3 characters to begin a search":
      "Please add at least 3 characters to begin a search",
    "No calendars found": "No calendars found",
    "We couldn't find any calendars matching '{{searchText}}'":
      "We couldn't find any calendars matching '{{searchText}}'",
    "Search...": "Search...",
    "Confirm selection": "Confirm selection",
    Close: "Close",
    "Add Teamworks calendars": "Add Teamworks calendars",
    "Loading profiles": "Loading profiles",
    "Search text must have at least 3 characters":
      "Search text must have at least 3 characters",
    "No results found": "No results found",
    "Calendar details": "Calendar details",
    "Remove calendar": "Remove calendar",
    "Are you sure you want to remove this calendar?":
      "Are you sure you want to remove this calendar?",
    "No calendars added": "No calendars added",
    "Unknown profile": "Unknown profile",
    "You don't have access to this hub profile":
      "You don't have access to this hub profile",
  },
  ScreenEventsPicker: {
    "Loading events": "Loading events",
    "Event countdown": "Event countdown",
    "Create event": "Create event",
    "Update event": "Update event",
    "Event title": "Event title",
    "Starts at": "Starts at",
    "Add opponent badge": "Add opponent badge",
    "Remove opponent badge": "Remove opponent badge",
    "Delete event": "Delete event",
    "Are you sure you want to delete this event?":
      "Are you sure you want to delete this event?",
    "Delete now": "Delete now",
    "Opponent badge position": "Opponent badge position",
    "Should the opponent badge be on the left or right?":
      "Should the opponent badge be on the left or right?",
    "i.e are you playing home or away?": "i.e are you playing home or away?",
    "Edit event": "Edit event",
  },
  ScreenConfigDrawer: {
    "Loading configuration": "Loading configuration",
    "Number of days the schedule should span. Max 5":
      "Number of days the schedule should span. Max 5",
    "Number of days": "Number of days",
    "Delete configuration": "Delete configuration",
    "Are you sure you want to delete this configuration?":
      "Are you sure you want to delete this configuration?",
    "Configuration deleted": "Configuration deleted",
    "Something went wrong": "Something went wrong",
    "Save changes": "Save changes",
    "Configuration updated": "Configuration updated",
    bottom_content_cards: "Cards",
    bottom_content_cards_desc: "Select cards to add text or imagery",
    media_content: "Media",
    media_content_desc: "Select from video, images and youtube content",
    multi_column_schedule: "Multi column schedule",
    multi_column_schedule_desc:
      "Select teamworks calendars to display on screen",
    multi_day_schedule: "Multi day schedule",
    multi_day_schedule_desc: "Select teamworks calendars to display on screen",
    right_schedule: "Schedule",
    right_schedule_desc: "Select teamworks calendars to display on screen",
    "%future added value": "Unknown component",
    "%future added value_desc": "Unknown component",
    "Configuration name": "Configuration name",
    "Add event countdown": "Add event countdown",
    "A countdown will be displayed until the event starts":
      "A countdown will be displayed until the event starts",
    "Not saved": "Not saved",
    "Please fix any errors and try again":
      "Please fix any errors and try again",
    "Give the configuration a name": "Give the configuration a name",
    "Try to give it a relevant name, to help you quickly find it":
      "Try to give it a relevant name, to help you quickly find it",
    "Pick a theme": "Pick a theme",
    "The theme will set colours and logo for your configuration":
      "The theme will set colours and logo for your configuration",
    "Add an event (optional)": "Add an event (optional)",
    "A countdown will be displayed on the configuration until the event starts":
      "A countdown will be displayed on the configuration until the event starts",
    "Add a Teamworks schedule": "Add a Teamworks schedule",
    "Upload media": "Upload media",
    "Add cards (optional)": "Add cards (optional)",
    "Add multiple Teamworks schedules": "Add multiple Teamworks schdules",
    "Unknown component": "Unknown component",
    "Events from the selected profiles will be shown on the configuration":
      "Events from the selected profiles will be shown on the configuration",
    "You can upload images, videos or add YouTube videos":
      "You can upload images, videos or add YouTube videos",
    "The configuration will rotate through the cards one at a time":
      "The configuration will rotate through the cards one at a time",
    "Each schedule will have its own column on the configuration":
      "Each schedule will have its own column on the configuration",
    "A multiday schedule will display the events from the selected profiles across multiple days":
      "A multiday schedule will display the events from the selected profiles across multiple days",
    "Save the configuration": "Save the configuration",
    "Once you are happy with your updates, save the changes":
      "Once you are happy with your updates, same the changes",
    "Take a tour": "Take a tour",
  },
  AnnouncementsScreen: {
    "Announcement text is required": "Announcement text is required",
    "Announcements updated": "Announcements updated",
    "Something went wrong": "Something went wrong",
    "Announcements allow you to post important information to be displayed in banners across all devices.":
      "Announcements allow you to post important information to be displayed in banners across all devices.",
    Announcements: "Announcements",
    "Loading announcements": "Loading announcements",
    "Manage announcements": "Manage announcements",
    "Announcements are displayed on all screens, at all times":
      "Announcements are displayed on all screens, at all times",
    "No announcements yet": "No announcements yet",
    "New announcement": "New announcement",
    "Save announcement": "Save announcement",
    "Note: announcements must be a single line. New lines will be ignored.":
      "Note: announcements must be a single line. New lines will be ignored.",
    "Delete announcement": "Delete announcement",
    "Are you sure you want to remove this annoucement?":
      "Are you sure you want to remove this annoucement?",
    "{{totalCount}} Announcements": "{{totalCount}} Announcements",
    Content: "Content",
    "Last updated": "Last updated",
  },
  CardsFormField: {
    "Add card": "Add card",
    "Add image": "Add image",
    "Remove image": "Remove image",
    "Remove card": "Remove card",
    "Are you sure you want to remove this card?":
      "Are you sure you want to remove this card?",
    "No cards added": "No cards added",
  },
  MediaFormField: {
    "Invalid YouTube link, please try again":
      "Invalid YouTube link, please try again",
    "File not supported, please upload an image or video":
      "File not supported, please upload an image or video",
    "Upload image/video": "Upload image/video",
    "Add YouTube": "Add YouTube",
    "Add YouTube video": "Add YouTube video",
    "Video title": "Video title",
    "YouTube link": "YouTube link",
    Image: "Image",
    Video: "Video",
    YouTube: "YouTube",
    "Something went wrong, file not uploaded":
      "Something went wrong, file not uploaded",
    "Cancel file upload?": "Cancel file upload?",
    "Are you sure you want to cancel this upload?":
      "Are you sure you want to cancel this upload?",
    "Upload image": "Upload image",
    "Upload video": "Upload video",
    "Uploading {{uploadPct}}%": "Uploading {{uploadPct}}%",
    "Upload complete": "Upload complete",
    Name: "Name",
    "Number seconds image displays before moving to next media item":
      "Number seconds image displays before moving to next media item",
    "Duration (seconds)": "Duration (seconds)",
    "Add image": "Add image",
    "Add video": "Add video",
    "Delete media": "Delete media",
    "Are you sure you want to delete this media?":
      "Are you sure you want to delete this media?",
    "Update duration": "Update duration",
    "Edit duration": "Edit duration",
    "Remove media": "Remove media",
    "No media added": "No media added",
  },
  MultiColumnScheduleFormField: {
    "Add column": "Add column",
    "Column title": "Column title",
    "Remove column": "Remove column",
    "Are you sure you want to remove this column?":
      "Are you sure you want to remove this column?",
  },
  AuthProvider: {
    "Fetching active users": "Fetching active users",
    "Setting organisations": "Setting organisations",
    "Logging you in": "Logging you in",
  },
  AccessDeniedScreen: {
    "You don't have access to Digital Displays":
      "You don't have access to Digital Displays",
    "Please contact Teamworks customer support to request access":
      "Please contact Teamworks customer support to request access",
    "Sign out": "Sign out",
    "Customer support": "Customer support",
    Reload: "Reload",
  },
  DeviceGroupsTab: {
    "Delete group": "Delete group",
    "Are you sure you want to delete this group?":
      "Are you sure you want to delete this group?",
    "Device group deleted successfully": "Device group deleted successfully",
    "Something went wrong": "Something went wrong",
    "Device group created successfully": "Device group created successfully",
    "Group updated": "Group updated",
    "New group": "New group",
    "Device groups allow you to group your display devices under one name.":
      "Device groups allow you to group your display devices under one name.",
    "Device groups": "Device groups",
    "{{totalCount}} Device groups": "{{totalCount}} Device groups",
    "Search groups...": "Search groups...",
    "Group name": "Group name",
    "Device count": "Device count",
    "Fetching groups": "Fetching groups",
    "No groups": "No groups",
    "Group name is required": "Group name is required",
    "Please select at least 2 devices": "Please select at least 2 devices",
    "Create group": "Create group",
    "Update group": "Update group",
    "Fetching devices...": "Fetching devices...",
    "Select devices": "Select devices",
    Devices: "Devices",
    Groups: "Groups",
  },
  ErrorState: {
    "Something went wrong": "Something went wrong",
    "Please try again, or contact Teamworks support if the issue persists":
      "Please try again, or contact Teamworks support if the issue persists",
  },
  ProductToursProvider: {
    Back: "Back",
    Next: "Next",
    Finish: "Finish",
  },
  Drawer: {
    Close: "Close",
  },
  InitialSearchParamsProvider: {
    "Initializing URL params": "Initializing URL params",
  },
  PreviewsProvider: {
    "Redirecting to preview": "Redirecting to preview",
  },
  PlaylistsScreen: {
    Playlists: "Playlists",
    "New playlist": "New playlist",
    "{{totalCount}} Playlists": "{{totalCount}} Playlists",
    Name: "Name",
    "Loading playlists": "Loading playlists",
    "No playlists have been setup yet": "No playlists have been setup yet",
    "Playlists allow you to join multiple configurations together.":
      "Playlists allow you to join multiple configurations together.",
    "No configurations selected": "No configurations selected",
    "Add configuration": "Add configuration",
    Cancel: "Cancel",
    "Create playlist": "Create playlist",
    "Delete playlist": "Delete playlist",
    "Are you sure you want to delete this playlist?":
      "Are you sure you want to delete this playlist?",
    "Loading playlist": "Loading playlist",
    "Save changes": "Save changes",
    "Configuration already in playlist": "Configuration already in playlist",
    "The selected configuration is already part of this playlist, do you want to add it again?":
      "The selected configuration is already part of this playlist, do you want to add it again?",
    "Remove from playlist": "Remove from playlist",
    Configuration: "Configuration",
    "Number loops": "Number loops",
    Active: "Active",
    "Playlist updated": "Playlist updated",
    "Something went wrong": "Something went wrong",
    "Last updated": "Last updated",
    "Digital Displays URL": "Digital Displays URL",
    "You may want to use this hardcoded url to integrate with systems that do not support our QR code setup.":
      "You may want to use this hardcoded url to integrate with systems that do not support our QR code setup.",
    "Get hardcoded URL": "Get hardcoded URL",
    l_layout_loop_description:
      "Media items will loop a given number of times before changing to the next configuration",
    media_only_loop_description:
      "Media items will loop a given number of times before changing to the next configuration",
    basic_loop_description:
      "Schedule will loop a given number of times before changing to the next configuration",
    multi_column_schedule_loop_description:
      "Schedule will loop a given number of times before changing to the next configuration",
    multi_day_schedule_loop_description:
      "Schedule will loop a given number of times before changing to the next configuration",
    schedule_only_loop_description:
      "Schedule will loop a given number of times before changing to the next configuration",
    "%future added value_loop_description": "Unknown configuration type",
    mvp_loop_description: "Unknown configuration type",
    timed_description:
      "Configuration will stay on screen for a given amount of time before changing to the next configuration",
    Looped: "Looped",
    Timed: "Timed",
    "Duration (minutes)": "Duration (minutes)",
    "Edit configuration": "Edit configuration",
    "New configuration": "New configuration",
    "Loading configuration": "Loading configuration",
    "Add an existing configuration to the playlist":
      "Add an existing configuration to the playlist",
    "Create a new configuration to add to the playlist":
      "Create a new configuration to add to the playlist",
  },
  PlaylistPicker: {
    Playlist: "Playlist",
    "Clear playlist": "Clear playlist",
    "No playlist selected": "No playlist selected",
    "Searching playlists": "Searching playlists",
    "No playlists found for '{{searchText}}'":
      "No playlists found for '{{searchText}}'",
    "No playlists found": "No playlists found",
  },
};

export type Localisation = typeof english;
export type Namespace = keyof Localisation;
