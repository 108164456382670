import React from "react";
import { makeStyles } from "@mui/styles";
import { iconSize } from "../helpers/theme";
import { HexColor } from "../helpers/types";

const useStyles = makeStyles({
  spinner: {
    display: "inline-block",
    position: "relative",
    "& > div": {
      boxSizing: "border-box",
      display: "block",
      position: "absolute",
      borderStyle: "solid",
      borderColor: "currentColor transparent transparent transparent",
      borderRadius: "50%",
      animation: "spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite",
    },
    "& > div:nth-child(1)": {
      animationDelay: "-0.45s",
    },
    "& > div:nth-child(2)": {
      animationDelay: "-0.3s",
    },
    "& > div:nth-child(3)": {
      animationDelay: "-0.15s",
    },
  },
});

export const LoadingSpinner = (props: {
  color?: HexColor;
  size?: "large" | "medium" | "small";
}) => {
  const classes = useStyles();
  const size = props.size ?? "large";
  const baseSize =
    size === "large"
      ? iconSize.medium
      : size === "medium"
        ? iconSize.regular
        : iconSize.small;

  const innerSize = Math.round(baseSize * 0.8);
  const borderSize = Math.round(baseSize / 10);

  return (
    <div
      className={classes.spinner}
      style={{ width: baseSize, height: baseSize }}
    >
      <div
        style={{
          width: innerSize,
          height: innerSize,
          borderWidth: borderSize,
          margin: borderSize,
        }}
      ></div>
      <div
        style={{
          width: innerSize,
          height: innerSize,
          borderWidth: borderSize,
          margin: borderSize,
        }}
      ></div>
      <div
        style={{
          width: innerSize,
          height: innerSize,
          borderWidth: borderSize,
          margin: borderSize,
        }}
      ></div>
      <div
        style={{
          width: innerSize,
          height: innerSize,
          borderWidth: borderSize,
          margin: borderSize,
        }}
      ></div>
    </div>
  );
};
