import React, { PropsWithChildren } from "react";
import { Column } from "./Flex";
import { makeStyles } from "@mui/styles";
import { borderRadius, colors, spacing } from "../helpers/theme";

const useStyles = makeStyles({
  page: {
    flexGrow: 1,
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    gap: spacing.small,
    background: colors.black,
  },
  gradientPurple: {
    width: "100vw",
    height: "100vh",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 0,
    background: `radial-gradient(circle, #5936BD 0%, transparent 50%, transparent 100%)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "-50vw 60vh",
  },
  gradientGreen: {
    width: "100vw",
    height: "100vh",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 0,
    background: `radial-gradient(circle, #0babca 0%, transparent 50%, transparent 100%)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "-30vw 70vh",
  },
  gradientBlue: {
    width: "100vw",
    height: "100vh",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 0,
    background: `radial-gradient(circle, ${colors.blue2} 0%, transparent 50%, transparent 100%)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "-10vw 80vh",
  },
  container: {
    width: 1000,
    maxWidth: `calc(100vw - ${spacing.small * 2}px)`,
    backgroundColor: colors.white,
    padding: spacing.medium,
    borderRadius: borderRadius.large,
    zIndex: 1,
  },
});

export const ErrorLayout = (props: PropsWithChildren) => {
  const classes = useStyles();
  return (
    <Column className={classes.page}>
      <Column className={classes.gradientBlue} />
      <Column className={classes.gradientGreen} />
      <Column className={classes.gradientPurple} />
      <Column className={classes.container}>{props.children}</Column>
    </Column>
  );
};
