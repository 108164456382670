import { type Localisation } from "./en";

export const portugueseBrazilian: Localisation = {
  dateFormats: {
    datePickerLocale: "pt-br",
    timeFormat: "HH:mm", // 14:30
    dayMonthYearFormat: "do MMMM yyyy", // 11th January 1988
    dayMonthYearTimeFormat: "do MMMM yyyy, HH:mm", // 11th January 1988, 14:30
  },
  App: {
    "Digital Displays": "Displays digitais",
    Loading: "Carregamento",
    "Checking authentication": "Verificação da autenticação",
    "Redirecting to login": "Redirecionamento para login",
    "Sign in with Teamworks": "Faça login com o Teamworks",
    Initalizing: "Inicialização",
    "Verifing authentication": "Verificar a autenticação",
    Redirecting: "Redirecionamento",
    "Welcome back!": "Bem-vindo de volta!",
    "Login to get started": "Faça login para começar",
  },
  AppSwitcher: {
    arms: "Compliance + Recruiting",
    hub: "Hub",
    inflcr: "Influencer",
    academics: "Academics",
    communities: "Communities",
    pulse: "Pulse",
    pathways: "Pathways",
    nutrition: "Nutrition",
    ams: "AMS",
    tv: "Digital Displays",
    trac: "Inventory Management",
    strength: "Strength + Conditioning",
    wallet: "Wallet",
  },
  Layout: {
    "Checking authentication": "Verificação da autenticação",
    Dashboard: "Painel de controle",
    Configurations: "Configurações",
    Devices: "Dispositivos",
    Themes: "Temas",
    Settings: "Configurações",
    Announcements: "Anúncios",
    "Teamworks Help": "Ajuda do Teamworks",
    "Teamworks Apps": "Aplicativos do Teamworks",
    "Privacy policy": "Política de privacidade",
    Logout: "Sair",
    "Loading, please wait...": "Carregando, aguarde...",
    Users: "Usuários",
    "Switch organisation": "Organização da troca",
    "Signing you out": "Assinando sua saída",
    "Open Menu": "Abrir menu",
    "Close Menu": "Fechar menu",
    Playlists: "Listas de reprodução",
  },
  ConfigsScreen: {
    "{{totalCount}} Configurations": "{{totalCount}} Configurações",
    "New configuration": "Nova configuração",
    "Configurations allow you to customise the look and content of your displays.":
      "As configurações permitem que você personalize a aparência e o conteúdo de seus monitores.",
    "Loading configurations": "Configurações de carregamento",
    Configurations: "Configurações",
    Name: "Nome",
    Template: "Modelo",
    Theme: "Tema",
    "Device count": "Contagem de dispositivos",
    "Created at": "Criado em",
    "No configurations have been setup yet":
      "Nenhuma configuração foi definida ainda",
    "No configurations found for '{{searchText}}'":
      "Não foram encontradas configurações para '{{searchText}}'",
    "Displays allow you to cast scheduled events and media to any device with an internet browser":
      "Os monitores permitem transmitir eventos programados e mídia para qualquer dispositivo com um navegador de Internet",
    "Create one now": "Crie um agora",
    "Create configuration": "Criar configuração",
    "Add custom videos, YouTube videos and images":
      "Adicionar vídeos personalizados, vídeos do YouTube e imagens",
    "Filter calendar to present a live schedule":
      "Filtrar o calendário para apresentar uma programação ao vivo",
    "Cycle through important updates on cards":
      "Percorrer atualizações importantes nos cartões",
    "Includes team crest, time and date":
      "Inclui o brasão da equipe, hora e data",
    "Display a schedule over multiple days":
      "Exibir uma programação em vários dias",
    "Display daily schedule for different teams and groups.":
      "Exibir a programação diária de diferentes equipes e grupos.",
    "Configuration deleted": "Configuração excluída",
    "Are you sure you want to delete this configuration?":
      "Tem certeza de que deseja excluir essa configuração?",
    "Delete configuration": "Excluir configuração",
    "Fetching configuration": "Obtenção de configuração",
    "Digital Displays URL": "URL dos monitores digitais",
    "You may want to use this hardcoded url to integrate with systems that do not support our QR code setup.":
      "Talvez você queira usar esse URL codificado para integrar-se a sistemas que não suportam nossa configuração de código QR.",
    "Please speak with your Teamworks administrator to ensure your IP addresses are permitted to access the link for security.":
      "Entre em contato com o administrador do Teamworks para garantir que seus endereços IP tenham permissão para acessar o link por segurança.",
    "Something went wrong": "Algo deu errado",
    "Edit configuration": "Editar configuração",
    "Get hardcoded URL": "Obter URL codificado",
    "Includes background image from theme": "Inclui imagem de fundo do tema",
    "Last active at {{lastActiveAt}}": "Última atividade às {{lastActiveAt}}",
    "Loading screen devices": "Dispositivos de tela de carregamento",
    "Search configurations...": "Configurações de pesquisa...",
    "Manage IP addresses": "Gerenciar endereços IP",
    "For systems which do not support our QR setup, these permitted IP addresses can be used to allow you access to hardcoded URLs.":
      "Para sistemas que não suportam nossa configuração de QR, esses endereços IP permitidos podem ser usados para permitir o acesso a URLs codificados.",
    "No permitted IPs yet": "Ainda não há IPs permitidos",
    "Add another": "Adicionar outro",
    "Save changes": "Salvar alterações",
    "Permitted IP addresses updated": "Endereços IP permitidos atualizados",
    "Last updated": "Última atualização",
    "Clear template": "Modelo claro",
    "No template selected": "Nenhum modelo selecionado",
  },
  DashboardScreen: { Dashboard: "Painel de controle" },
  DevicesScreen: {
    Devices: "Dispositivos",
    Groups: "Grupos",
    "{{totalCount}} Devices": "{{totalCount}} Dispositivos",
    "New device": "Novo dispositivo",
    "Devices are the physical displays you have linked to your account.":
      "Os dispositivos são os monitores físicos que você vinculou à sua conta.",
    "No devices have been setup yet":
      "Nenhum dispositivo foi configurado ainda",
    "No devices found for '{{searchText}}'":
      "Nenhum dispositivo encontrado para '{{searchText}}'",
    "Device name": "Nome do dispositivo",
    Facility: "Instalações",
    Configuration: "Configuração",
    "Facility not set": "Instalação não definida",
    "Config not set": "Configuração não definida",
    "Create device": "Criar dispositivo",
    "Update device": "Atualizar dispositivo",
    "Screen device updated": "Dispositivo de tela atualizado",
    "Something went wrong": "Algo deu errado",
    "Adding new display device": "Adição de um novo dispositivo de exibição",
    addNewDeviceDescription:
      "Para adicionar um novo dispositivo, acesse <0>{{url}}</0> no dispositivo que você deseja vincular.",
    "You will be shown a QR code which can be scanned by your camera.":
      "Você verá um código QR que pode ser escaneado pela sua câmera.",
    "Start scanning": "Iniciar a varredura",
    Ok: "Ok",
    "Are you sure you want to delete this device?":
      "Tem certeza de que deseja excluir esse dispositivo?",
    "Delete device": "Excluir dispositivo",
    "Fetching devices": "Dispositivos de busca",
    Timezone: "Fuso horário",
    "Display name is required": "O nome de exibição é obrigatório",
    "Display name must be at least 3 characters long":
      "O nome de exibição deve ter pelo menos 3 caracteres",
    "Device setup successful": "Configuração do dispositivo bem-sucedida",
    "Switch organisation": "Organização da troca",
    "Search devices...": "Dispositivos de pesquisa...",
    "Device not found": "Dispositivo não encontrado",
    "It may have been deleted, or you do not have permission to view it.":
      "Ele pode ter sido excluído ou você não tem permissão para visualizá-lo.",
    "Loading device": "Dispositivo de carregamento",
    "Last updated": "Última atualização",
    config: "Configuração",
    playlist: "Lista de reprodução",
    "Playlist not set": "Lista de reprodução não definida",
    "Device mode": "Modo de dispositivo",
    "Should this device display a configuration or a playlist?":
      "Esse dispositivo deve exibir uma configuração ou uma lista de reprodução?",
  },
  NotFoundScreen: {
    "Oops! You seem to be lost.": "Ops! Parece que você se perdeu.",
    "Return to dashboard": "Retornar ao painel de controle",
  },
  ThemeScreen: {
    "New theme": "Novo tema",
    "Themes allow you to customise the colours of your configurations.":
      "Os temas permitem que você personalize as cores de suas configurações.",
    Themes: "Temas",
    "You don't have any themes yet": "Você ainda não tem nenhum tema",
    "Themes allow you to customise the colours used by your templates":
      "Os temas permitem que você personalize as cores usadas pelos seus modelos",
    "Create one now": "Crie um agora",
    "Theme name": "Nome do tema",
    "Last updated": "Última atualização",
    "Create theme": "Criar tema",
    "Update theme": "Atualizar o tema",
    background: "Histórico",
    backgroundText: "Texto de fundo",
    accent: "Sotaque",
    accentText: "Acentuar o texto",
    announcementBar: "Barra de anúncios",
    announcementBarText: "Texto da barra de anúncios",
    "Invalid hex code": "Código hexadecimal inválido",
    "New theme created": "Novo tema criado",
    "Theme updated": "Tema atualizado",
    "Screen theme deleted": "Tema de tela excluído",
    "Something went wrong": "Algo deu errado",
    "Are you sure you want to delete this theme?":
      "Tem certeza de que deseja excluir esse tema?",
    "Delete theme": "Excluir tema",
    "Loading themes": "Carregando temas",
    "Upload logo": "Fazer upload do logotipo",
    "Remove logo": "Remover logotipo",
    "Upload background image": "Carregar imagem de fundo",
    "Remove image": "Remover imagem",
    "Theme not found": "Tema não encontrado",
    "It may have been deleted, or you do not have permission to view it":
      "Ele pode ter sido excluído ou você não tem permissão para visualizá-lo",
    "Loading theme": "Carregando o tema",
    "Search themes...": "Pesquisar temas...",
    "{{totalCount}} Themes": "{{totalCount}} Temas",
    "What's a theme?": "O que é um tema?",
    "Themes allow you to customise the appearance of your configurations":
      "Os temas permitem que você personalize a aparência de suas configurações",
    "Clicking this button will begin the process of creating a new one.":
      "Clicar nesse botão iniciará o processo de criação de um novo.",
    "Give your theme a memorable name": "Dê ao seu tema um nome memorável",
    "This will help you find it later when assigning it to a configuration.":
      "Isso o ajudará a encontrá-lo mais tarde ao atribuí-lo a uma configuração.",
    "Upload a logo (optional)": "Faça upload de um logotipo (opcional)",
    "If you do not upload a logo, a Teamworks logo will appear in its place.":
      "Se você não carregar um logotipo, um logotipo da Teamworks aparecerá em seu lugar.",
    "Upload a background image (optional)":
      "Carregue uma imagem de fundo (opcional)",
    "Some configurations make use of a background image. If an image is not uploaded, the background colour selected below will be used.":
      "Algumas configurações fazem uso de uma imagem de fundo. Se uma imagem não for carregada, será usada a cor de fundo selecionada abaixo.",
    "Customise the colours you want to use":
      "Personalize as cores que você deseja usar",
    "The 'text' options should be a contrasting colour, to ensure it can be easily read.":
      "As opções de “texto” devem ter uma cor contrastante, para garantir que possam ser lidas facilmente.",
    "Once you are ready, save the theme by clicking this button":
      "Quando estiver pronto, salve o tema clicando neste botão",
    "Your theme will then be available to be selected when managing a configuration.":
      "Seu tema estará então disponível para ser selecionado ao gerenciar uma configuração.",
    "Theme contrast": "Contraste de temas",
    "Some content appears in containers that are semi transparent and overlayed on your chosen background colour.":
      "Alguns conteúdos são exibidos em contêineres semitransparentes e sobrepostos à cor de fundo escolhida.",
    "Would you like to lighten or darken your background?":
      "Gostaria de clarear ou escurecer seu plano de fundo?",
    Dark: "Escuro",
    Light: "Luz",
    "Theme preview": "Visualização do tema",
    Close: "Fechar",
    Preview: "Prévia",
  },
  ScreenTemplateNames: {
    "L shape with media": "Formato em L com mídia",
    "Media only": "Somente mídia",
    "Schedule only": "Somente programação",
    "Multi day schedule": "Programação de vários dias",
    "Multi column schedule": "Programação de várias colunas",
    Basic: "Básico",
  },
  DialogProvider: {
    Cancel: "Cancelar",
    Confirm: "Confirmar",
    Close: "Fechar",
    Ok: "Ok",
    "Close without saving?": "Fechar sem salvar?",
    "Close without saving": "Fechar sem salvar",
    "Any unsaved changes will be lost":
      "Todas as alterações não salvas serão perdidas",
    "Back to form": "Voltar ao formulário",
  },
  ConfigurationPicker: {
    Configuration: "Configuração",
    "No configurations found for '{{searchText}}'":
      "Não foram encontradas configurações para '{{searchText}}'",
    "No configurations found": "Nenhuma configuração encontrada",
    "No configuration selected": "Nenhuma configuração selecionada",
    "Clear configuration": "Limpar configuração",
    "Searching configurations": "Configurações de pesquisa",
  },
  ThemePicker: {
    Theme: "Tema",
    "Clear theme": "Tema claro",
    "Screen theme": "Tema da tela",
    "No theme selected": "Nenhum tema selecionado",
    "No themes found matching '{{searchText}}'":
      "Não foram encontrados temas correspondentes a '{{searchText}}'",
    "No themes found": "Nenhum tema encontrado",
    "Loading themes...": "Carregando temas...",
  },
  MarkdownField: {
    Preview: "Prévia",
    Remove: "Remover",
    "Nothing to preview": "Nada para visualizar",
    Write: "Escrever",
  },
  ErrorScreen: {
    "Something went wrong": "Algo deu errado",
    "Sorry for the inconvience, our engineers have been notified and will be working on a fix":
      "Desculpe pelo inconveniente, nossos engenheiros foram notificados e estarão trabalhando em uma correção",
    "If this issue persists, please contact Teamworks customer support":
      "Se o problema persistir, entre em contato com o suporte ao cliente da Teamworks",
    Reload: "Recarregar",
    "Customer support": "Suporte ao cliente",
  },
  OrganisationSwitcher: {
    "Fetching organisations": "Organizações de busca",
    "Select an organisation": "Selecione uma organização",
    "Switching profile": "Switching profile",
  },
  TimeZonePicker: {
    Timezone: "Fuso horário",
  },
  MatchesDrawer: {
    "Add match": "Adicionar correspondência",
    "Fetching event": "Evento de busca",
    "Event title": "Título do evento",
    "Starts at": "Começa em",
    "Create event": "Criar evento",
  },
  FileUploadField: {
    "Upload file": "Carregar arquivo",
    "Remove file": "Remover arquivo",
  },
  HubProfilePicker: {
    Search: "Pesquisa",
    "Clear search text": "Limpar o texto da pesquisa",
    "Search text too short": "Texto de pesquisa muito curto",
    "Please add at least 3 characters to begin a search":
      "Adicione pelo menos 3 caracteres para iniciar uma pesquisa",
    "No calendars found": "Nenhum calendário encontrado",
    "We couldn't find any calendars matching '{{searchText}}'":
      "Não foi possível encontrar calendários que correspondam a '{{searchText}}'",
    "Search...": "Pesquisar...",
    "Confirm selection": "Confirmar a seleção",
    Close: "Fechar",
    "Add Teamworks calendars": "Adicionar calendários do Teamworks",
    "Loading profiles": "Perfis de carregamento",
    "Search text must have at least 3 characters":
      "O texto da pesquisa deve ter pelo menos 3 caracteres",
    "No results found": "Nenhum resultado encontrado",
    "Calendar details": "Detalhes do calendário",
    "Remove calendar": "Remover calendário",
    "Are you sure you want to remove this calendar?":
      "Tem certeza de que deseja remover este calendário?",
    "No calendars added": "Nenhum calendário adicionado",
    "Unknown profile": "Perfil desconhecido",
    "You don't have access to this hub profile":
      "Você não tem acesso a este perfil do hub",
  },
  ScreenEventsPicker: {
    "Loading events": "Eventos de carregamento",
    "Event countdown": "Contagem regressiva do evento",
    "Create event": "Criar evento",
    "Update event": "Atualizar evento",
    "Event title": "Título do evento",
    "Starts at": "Começa em",
    "Add opponent badge": "Adicionar emblema de oponente",
    "Remove opponent badge": "Remover o crachá do oponente",
    "Delete event": "Excluir evento",
    "Are you sure you want to delete this event?":
      "Tem certeza de que deseja excluir esse evento?",
    "Delete now": "Excluir agora",
    "Opponent badge position": "Posição do crachá do oponente",
    "Should the opponent badge be on the left or right?":
      "O emblema do oponente deve estar à esquerda ou à direita?",
    "i.e are you playing home or away?":
      "Ou seja, você está jogando em casa ou fora?",
    "Edit event": "Editar evento",
  },
  ScreenConfigDrawer: {
    "Loading configuration": "Configuração de carregamento",
    "Number of days the schedule should span. Max 5":
      "Número de dias que a programação deve abranger. Máximo 5",
    "Number of days": "Número de dias",
    "Delete configuration": "Excluir configuração",
    "Are you sure you want to delete this configuration?":
      "Tem certeza de que deseja excluir essa configuração?",
    "Configuration deleted": "Configuração excluída",
    "Something went wrong": "Algo deu errado",
    "Save changes": "Salvar alterações",
    "Configuration updated": "Configuração atualizada",
    bottom_content_cards: "Cartões",
    bottom_content_cards_desc:
      "Selecione cartões para adicionar texto ou imagens",
    media_content: "Mídia",
    media_content_desc: "Selecione entre vídeos, imagens e conteúdo do YouTube",
    multi_column_schedule: "Programação de várias colunas",
    multi_column_schedule_desc:
      "Selecione os calendários do teamworks a serem exibidos na tela",
    multi_day_schedule: "Programação de vários dias",
    multi_day_schedule_desc:
      "Selecione os calendários do teamworks a serem exibidos na tela",
    right_schedule: "Cronograma",
    right_schedule_desc:
      "Selecione os calendários do teamworks a serem exibidos na tela",
    "%future added value": "Componente desconhecido",
    "%future added value_desc": "Componente desconhecido",
    "Configuration name": "Nome da configuração",
    "Add event countdown": "Adicionar contagem regressiva de eventos",
    "A countdown will be displayed until the event starts":
      "Uma contagem regressiva será exibida até o início do evento",
    "Not saved": "Não salvo",
    "Please fix any errors and try again": "Corrija os erros e tente novamente",
    "Give the configuration a name": "Dê um nome à configuração",
    "Try to give it a relevant name, to help you quickly find it":
      "Tente dar a ela um nome relevante, para ajudá-lo a encontrá-la rapidamente",
    "Pick a theme": "Escolha um tema",
    "The theme will set colours and logo for your configuration":
      "O tema definirá as cores e o logotipo para sua configuração",
    "Add an event (optional)": "Adicionar um evento (opcional)",
    "A countdown will be displayed on the configuration until the event starts":
      "Uma contagem regressiva será exibida na configuração até o início do evento",
    "Add a Teamworks schedule": "Adicionar uma programação do Teamworks",
    "Upload media": "Fazer upload de mídia",
    "Add cards (optional)": "Adicionar cartões (opcional)",
    "Add multiple Teamworks schedules":
      "Adicionar várias programações do Teamworks",
    "Unknown component": "Componente desconhecido",
    "Events from the selected profiles will be shown on the configuration":
      "Os eventos dos perfis selecionados serão exibidos na configuração",
    "You can upload images, videos or add YouTube videos":
      "Você pode carregar imagens, vídeos ou adicionar vídeos do YouTube",
    "The configuration will rotate through the cards one at a time":
      "A configuração passará pelos cartões, um de cada vez",
    "Each schedule will have its own column on the configuration":
      "Cada programação terá sua própria coluna na configuração",
    "A multiday schedule will display the events from the selected profiles across multiple days":
      "Uma programação de vários dias exibirá os eventos dos perfis selecionados em vários dias",
    "Save the configuration": "Salvar a configuração",
    "Once you are happy with your updates, save the changes":
      "Quando estiver satisfeito com suas atualizações, faça as mesmas alterações",
    "Take a tour": "Faça um tour",
  },
  AnnouncementsScreen: {
    "Announcement text is required": "O texto do anúncio é necessário",
    "Announcements updated": "Anúncios atualizados",
    "Something went wrong": "Algo deu errado",
    "Announcements allow you to post important information to be displayed in banners across all devices.":
      "Os anúncios permitem que você publique informações importantes para serem exibidas em banners em todos os dispositivos.",
    Announcements: "Anúncios",
    "Loading announcements": "Carregando anúncios",
    "Manage announcements": "Gerenciar anúncios",
    "Announcements are displayed on all screens, at all times":
      "Os anúncios são exibidos em todas as telas, o tempo todo",
    "No announcements yet": "Nenhum anúncio ainda",
    "New announcement": "Novo anúncio",
    "Save announcement": "Salvar anúncio",
    "Note: announcements must be a single line. New lines will be ignored.":
      "Observação: os anúncios devem ter uma única linha. Novas linhas serão ignoradas.",
    "Delete announcement": "Excluir anúncio",
    "Are you sure you want to remove this annoucement?":
      "Tem certeza de que deseja remover este anúncio?",
    "{{totalCount}} Announcements": "{{totalCount}} Anúncios",
    Content: "Conteúdo",
    "Last updated": "Última atualização",
  },
  CardsFormField: {
    "Add card": "Adicionar cartão",
    "Add image": "Adicionar imagem",
    "Remove image": "Remover imagem",
    "Remove card": "Remover cartão",
    "Are you sure you want to remove this card?":
      "Tem certeza de que deseja remover esse cartão?",
    "No cards added": "Nenhum cartão adicionado",
  },
  MediaFormField: {
    "Invalid YouTube link, please try again":
      "Link inválido do YouTube, tente novamente",
    "File not supported, please upload an image or video":
      "Arquivo não suportado, carregue uma imagem ou vídeo",
    "Upload image/video": "Carregar imagem/vídeo",
    "Add YouTube": "Adicionar YouTube",
    "Add YouTube video": "Adicionar vídeo do YouTube",
    "Video title": "Título do vídeo",
    "YouTube link": "Link do YouTube",
    Image: "Imagem",
    Video: "Vídeo",
    YouTube: "YouTube",
    "Something went wrong, file not uploaded":
      "Algo deu errado, o arquivo não foi carregado",
    "Cancel file upload?": "Cancelar upload de arquivo?",
    "Are you sure you want to cancel this upload?":
      "Tem certeza de que deseja cancelar esse upload?",
    "Upload image": "Carregar imagem",
    "Upload video": "Carregar vídeo",
    "Uploading {{uploadPct}}%": "Carregamento de {{uploadPct}}%",
    "Upload complete": "Upload completo",
    Name: "Nome",
    "Number seconds image displays before moving to next media item":
      "Número de segundos de exibição da imagem antes de passar para o próximo item de mídia",
    "Duration (seconds)": "Duração (segundos)",
    "Add image": "Adicionar imagem",
    "Add video": "Adicionar vídeo",
    "Delete media": "Excluir mídia",
    "Are you sure you want to delete this media?":
      "Tem certeza de que deseja excluir essa mídia?",
    "Update duration": "Duração da atualização",
    "Edit duration": "Editar duração",
    "Remove media": "Remover mídia",
    "No media added": "Nenhuma mídia adicionada",
  },
  MultiColumnScheduleFormField: {
    "Add column": "Adicionar coluna",
    "Column title": "Título da coluna",
    "Remove column": "Remover coluna",
    "Are you sure you want to remove this column?":
      "Tem certeza de que deseja remover essa coluna?",
  },
  AuthProvider: {
    "Fetching active users": "Obtenção de usuários ativos",
    "Setting organisations": "Estabelecimento de organizações",
    "Logging you in": "Fazendo seu login",
  },
  AccessDeniedScreen: {
    "You don't have access to Digital Displays":
      "Você não tem acesso a monitores digitais",
    "Please contact Teamworks customer support to request access":
      "Entre em contato com o suporte ao cliente da Teamworks para solicitar acesso",
    "Sign out": "Sair do sistema",
    "Customer support": "Suporte ao cliente",
    Reload: "Recarregar",
  },
  DeviceGroupsTab: {
    "Delete group": "Excluir grupo",
    "Are you sure you want to delete this group?":
      "Tem certeza de que deseja excluir esse grupo?",
    "Device group deleted successfully":
      "Grupo de dispositivos excluído com êxito",
    "Something went wrong": "Algo deu errado",
    "Device group created successfully":
      "Grupo de dispositivos criado com sucesso",
    "Group updated": "Grupo atualizado",
    "New group": "Novo grupo",
    "Device groups allow you to group your display devices under one name.":
      "Os grupos de dispositivos permitem agrupar os dispositivos de exibição em um único nome.",
    "Device groups": "Grupos de dispositivos",
    "{{totalCount}} Device groups": "{{totalCount}} Grupos de dispositivos",
    "Search groups...": "Grupos de pesquisa...",
    "Group name": "Nome do grupo",
    "Device count": "Contagem de dispositivos",
    "Fetching groups": "Obtenção de grupos",
    "No groups": "Nenhum grupo",
    "Group name is required": "O nome do grupo é obrigatório",
    "Please select at least 2 devices": "Selecione pelo menos 2 dispositivos",
    "Create group": "Criar grupo",
    "Update group": "Grupo de atualização",
    "Fetching devices...": "Busca de dispositivos...",
    "Select devices": "Selecionar dispositivos",
    Devices: "Dispositivos",
    Groups: "Grupos",
  },
  ErrorState: {
    "Something went wrong": "Algo deu errado",
    "Please try again, or contact Teamworks support if the issue persists":
      "Tente novamente ou entre em contato com o suporte da Teamworks se o problema persistir",
  },
  ProductToursProvider: {
    Back: "Voltar",
    Next: "Próximo",
    Finish: "Acabamento",
  },
  Drawer: {
    Close: "Fechar",
  },
  InitialSearchParamsProvider: {
    "Initializing URL params": "Inicialização de parâmetros de URL",
  },
  PreviewsProvider: {
    "Redirecting to preview": "Redirecionando para a visualização",
  },
  PlaylistsScreen: {
    Playlists: "Listas de reprodução",
    "New playlist": "Nova lista de reprodução",
    "{{totalCount}} Playlists": "{{totalCount}} listas de reprodução",
    Name: "Nome",
    "Loading playlists": "Carregando listas de reprodução",
    "No playlists have been setup yet":
      "Nenhuma lista de reprodução foi configurada ainda",
    "Playlists allow you to join multiple configurations together.":
      "As listas de reprodução permitem que você junte várias configurações.",
    "No configurations selected": "Nenhuma configuração selecionada",
    "Add configuration": "Adicionar configuração",
    Cancel: "Cancelar",
    "Create playlist": "Criar lista de reprodução",
    "Delete playlist": "Excluir lista de reprodução",
    "Are you sure you want to delete this playlist?":
      "Tem certeza de que deseja excluir esta lista de reprodução?",
    "Loading playlist": "Carregando a lista de reprodução",
    "Save changes": "Salvar alterações",
    "Configuration already in playlist":
      "Configuração já existente na lista de reprodução",
    "The selected configuration is already part of this playlist, do you want to add it again?":
      "A configuração selecionada já faz parte desta lista de reprodução, você deseja adicioná-la novamente?",
    "Remove from playlist": "Remover da lista de reprodução",
    Configuration: "Configuração",
    "Number loops": "Loops de número",
    Active: "Ativo",
    "Playlist updated": "Lista de reprodução atualizada",
    "Something went wrong": "Algo deu errado",
    "Last updated": "Última atualização",
    "Digital Displays URL": "URL dos monitores digitais",
    "You may want to use this hardcoded url to integrate with systems that do not support our QR code setup.":
      "Talvez você queira usar esse URL codificado para integrar-se a sistemas que não suportam nossa configuração de código QR.",
    "Get hardcoded URL": "Obter URL codificado",
    l_layout_loop_description:
      "Os itens de mídia serão repetidos um determinado número de vezes antes de mudar para a próxima configuração",
    media_only_loop_description:
      "Os itens de mídia serão repetidos um determinado número de vezes antes de mudar para a próxima configuração",
    basic_loop_description:
      "O cronograma será repetido um determinado número de vezes antes de mudar para a próxima configuração",
    multi_column_schedule_loop_description:
      "O cronograma será repetido um determinado número de vezes antes de mudar para a próxima configuração",
    multi_day_schedule_loop_description:
      "O cronograma será repetido um determinado número de vezes antes de mudar para a próxima configuração",
    schedule_only_loop_description:
      "O cronograma será repetido um determinado número de vezes antes de mudar para a próxima configuração",
    "%future added value_loop_description": "Tipo de configuração desconhecido",
    mvp_loop_description: "Tipo de configuração desconhecido",
    timed_description:
      "A configuração permanecerá na tela por um determinado período de tempo antes de mudar para a próxima configuração",
    Looped: "Em loop",
    Timed: "Cronometrado",
    "Duration (minutes)": "Duração (minutos)",
    "Edit configuration": "Editar configuração",
    "New configuration": "Nova configuração",
    "Loading configuration": "Carregando configuração",
    "Add an existing configuration to the playlist":
      "Adicionar uma configuração existente à playlist",
    "Create a new configuration to add to the playlist":
      "Criar uma nova configuração para adicionar à playlist",
  },
  PlaylistPicker: {
    Playlist: "Lista de reprodução",
    "Clear playlist": "Limpar lista de reprodução",
    "No playlist selected": "Nenhuma lista de reprodução selecionada",
    "Searching playlists": "Pesquisa de listas de reprodução",
    "No playlists found for '{{searchText}}'":
      "Não foram encontradas listas de reprodução para '{{searchText}}'",
    "No playlists found": "Nenhuma lista de reprodução encontrada",
  },
};
