import { type Localisation } from "./en";

export const german: Localisation = {
  dateFormats: {
    datePickerLocale: "de",
    timeFormat: "HH:mm", // 14:30
    dayMonthYearFormat: "do MMMM yyyy", // 11th January 1988
    dayMonthYearTimeFormat: "do MMMM yyyy, HH:mm", // 11th January 1988, 14:30
  },
  App: {
    "Digital Displays": "Digitale Anzeigen",
    Loading: "die Beladung",
    "Checking authentication": "Authentifizierung prüfen",
    "Redirecting to login": "Weiterleitung zum Login",
    "Sign in with Teamworks": "Melden Sie sich mit Teamworks an",
    Initalizing: "Initialisierung",
    "Verifing authentication": "Authentifizierung überprüfen",
    Redirecting: "Umleiten",
    "Welcome back!": "Willkommen zurück!",
    "Login to get started": "Melden Sie sich an, um loszulegen",
  },
  AppSwitcher: {
    arms: "Compliance + Recruiting",
    hub: "Hub",
    inflcr: "Influencer",
    academics: "Academics",
    communities: "Communities",
    pulse: "Pulse",
    pathways: "Pathways",
    nutrition: "Nutrition",
    ams: "AMS",
    tv: "Digital Displays",
    trac: "Inventory Management",
    strength: "Strength + Conditioning",
    wallet: "Wallet",
  },
  Layout: {
    "Checking authentication": "Authentifizierung prüfen",
    Dashboard: "Armaturenbrett",
    Configurations: "Konfigurationen",
    Devices: "Geräte",
    Themes: "Themen",
    Settings: "Einstellungen",
    Announcements: "Ankündigungen",
    "Teamworks Help": "Teamworks-Hilfe",
    "Teamworks Apps": "Teamworks-Apps",
    "Privacy policy": "Datenschutzrichtlinie",
    Logout: "Abmelden",
    "Loading, please wait...": "Laden, bitte warten...",
    Users: "Benutzer",
    "Switch organisation": "Organisation wechseln",
    "Signing you out": "Ich melde dich ab",
    "Open Menu": "Menü öffnen",
    "Close Menu": "Menü schließen",
    Playlists: "Wiedergabelisten",
  },
  ConfigsScreen: {
    "{{totalCount}} Configurations": "{{totalCount}} Konfigurationen",
    "New configuration": "Neue Konfiguration",
    "Configurations allow you to customise the look and content of your displays.":
      "Mit Konfigurationen können Sie das Aussehen und den Inhalt Ihrer Anzeigen anpassen.",
    "Loading configurations": "Konfigurationen werden geladen",
    Configurations: "Konfigurationen",
    Name: "Name",
    Template: "Vorlage",
    Theme: "Thema",
    "Device count": "Geräteanzahl",
    "Created at": "Erstellt bei",
    "No configurations have been setup yet":
      "Es wurden noch keine Konfigurationen eingerichtet",
    "No configurations found for '{{searchText}}'":
      "Keine Konfigurationen für '{{searchText}}' gefunden",
    "Displays allow you to cast scheduled events and media to any device with an internet browser":
      "Mit Displays können Sie geplante Ereignisse und Medien auf jedes Gerät mit einem Internetbrowser übertragen",
    "Create one now": "Erstellen Sie jetzt eines",
    "Create configuration": "Konfiguration erstellen",
    "Add custom videos, YouTube videos and images":
      "Fügen Sie benutzerdefinierte Videos, YouTube-Videos und Bilder hinzu",
    "Filter calendar to present a live schedule":
      "Filtern Sie den Kalender, um einen Live-Zeitplan anzuzeigen",
    "Cycle through important updates on cards":
      "Blättern Sie durch wichtige Kartenaktualisierungen",
    "Includes team crest, time and date":
      "Enthält Teamwappen, Uhrzeit und Datum",
    "Display a schedule over multiple days":
      "Zeigen Sie einen Zeitplan über mehrere Tage an",
    "Display daily schedule for different teams and groups.":
      "Zeigen Sie den Tagesplan für verschiedene Teams und Gruppen an.",
    "Configuration deleted": "Konfiguration gelöscht",
    "Are you sure you want to delete this configuration?":
      "Sind Sie sicher, dass Sie diese Konfiguration löschen möchten?",
    "Delete configuration": "Konfiguration löschen",
    "Fetching configuration": "Konfiguration abrufen",
    "Digital Displays URL": "URL für digitale Anzeigen",
    "You may want to use this hardcoded url to integrate with systems that do not support our QR code setup.":
      "Möglicherweise möchten Sie diese fest codierte URL zur Integration in Systeme verwenden, die unsere QR-Code-Einrichtung nicht unterstützen.",
    "Please speak with your Teamworks administrator to ensure your IP addresses are permitted to access the link for security.":
      "Bitte sprechen Sie mit Ihrem Teamworks-Administrator, um sicherzustellen, dass Ihre IP-Adressen aus Sicherheitsgründen auf den Link zugreifen dürfen.",
    "Something went wrong": "Etwas ist schief gelaufen",
    "Edit configuration": "Konfiguration bearbeiten",
    "Get hardcoded URL": "Holen Sie sich eine hartcodierte URL",
    "Includes background image from theme":
      "Enthält ein Hintergrundbild aus dem Thema",
    "Last active at {{lastActiveAt}}": "Zuletzt aktiv um {{lastActiveAt}}",
    "Loading screen devices": "Bildschirmgeräte werden geladen",
    "Search configurations...": "Konfigurationen suchen...",
    "Manage IP addresses": "IP-Adressen verwalten",
    "For systems which do not support our QR setup, these permitted IP addresses can be used to allow you access to hardcoded URLs.":
      "Für Systeme, die unser QR-Setup nicht unterstützen, können diese erlaubten IP-Adressen verwendet werden, um Ihnen den Zugriff auf hartcodierte URLs zu ermöglichen.",
    "No permitted IPs yet": "Noch keine erlaubten IPs",
    "Add another": "Fügen Sie einen weiteren hinzu",
    "Save changes": "Änderungen speichern",
    "Permitted IP addresses updated": "Zulässige IP-Adressen aktualisiert",
    "Last updated": "Zuletzt aktualisiert",
    "Clear template": "Klare Vorlage",
    "No template selected": "Keine Vorlage ausgewählt",
  },
  DashboardScreen: { Dashboard: "Armaturenbrett" },
  DevicesScreen: {
    Devices: "Geräte",
    Groups: "Gruppen",
    "{{totalCount}} Devices": "{{totalCount}} Geräte",
    "New device": "Neues Gerät",
    "Devices are the physical displays you have linked to your account.":
      "Geräte sind die physischen Displays, die Sie mit Ihrem Konto verknüpft haben.",
    "No devices have been setup yet":
      "Es wurden noch keine Geräte eingerichtet",
    "No devices found for '{{searchText}}'":
      "Keine Geräte für '{{searchText}}' gefunden",
    "Device name": "Gerätename",
    Facility: "Einrichtung",
    Configuration: "Konfiguration",
    "Facility not set": "Einrichtung nicht eingestellt",
    "Config not set": "Konfiguration nicht festgelegt",
    "Create device": "Gerät erstellen",
    "Update device": "Gerät aktualisieren",
    "Screen device updated": "Bildschirmgerät aktualisiert",
    "Something went wrong": "Etwas ist schief gelaufen",
    "Adding new display device": "Neues Anzeigegerät hinzufügen",
    addNewDeviceDescription:
      "Um ein neues Gerät hinzuzufügen, besuchen Sie <0>{{url}}</0> auf dem Gerät, das Sie verknüpfen möchten.",
    "You will be shown a QR code which can be scanned by your camera.":
      "Ihnen wird ein QR-Code angezeigt, der von Ihrer Kamera gescannt werden kann.",
    "Start scanning": "Beginnen Sie mit dem Scannen",
    Ok: "OK",
    "Are you sure you want to delete this device?":
      "Möchten Sie dieses Gerät wirklich löschen?",
    "Delete device": "Gerät löschen",
    "Fetching devices": "Geräte abrufen",
    Timezone: "Zeitzone",
    "Display name is required": "Anzeigename ist erforderlich",
    "Display name must be at least 3 characters long":
      "Der Anzeigename muss mindestens 3 Zeichen lang sein",
    "Device setup successful": "Geräteeinrichtung erfolgreich",
    "Switch organisation": "Organisation wechseln",
    "Search devices...": "Geräte suchen...",
    "Device not found": "Gerät nicht gefunden",
    "It may have been deleted, or you do not have permission to view it.":
      "Möglicherweise wurde es gelöscht oder Sie haben keine Berechtigung, es anzuzeigen.",
    "Loading device": "Ladevorrichtung",
    "Last updated": "Zuletzt aktualisiert",
    config: "Konfiguration",
    playlist: "Wiedergabeliste",
    "Playlist not set": "Playlist nicht eingestellt",
    "Device mode": "Gerätemodus",
    "Should this device display a configuration or a playlist?":
      "Soll dieses Gerät eine Konfiguration oder eine Playlist anzeigen?",
  },
  NotFoundScreen: {
    "Oops! You seem to be lost.": "Hoppla! Du scheinst verloren zu sein.",
    "Return to dashboard": "Zurück zum Dashboard",
  },
  ThemeScreen: {
    "New theme": "Neues Thema",
    "Themes allow you to customise the colours of your configurations.":
      "Mit Themes können Sie die Farben Ihrer Konfigurationen anpassen.",
    Themes: "Themen",
    "You don't have any themes yet": "Sie haben noch keine Themen",
    "Themes allow you to customise the colours used by your templates":
      "Mit Themes können Sie die von Ihren Vorlagen verwendeten Farben anpassen",
    "Create one now": "Erstellen Sie jetzt eines",
    "Theme name": "Themenname",
    "Last updated": "Zuletzt aktualisiert",
    "Create theme": "Thema erstellen",
    "Update theme": "Thema aktualisieren",
    background: "Hintergrund",
    backgroundText: "Hintergrundtext",
    accent: "Akzent",
    accentText: "Akzenttext",
    announcementBar: "Ankündigungsleiste",
    announcementBarText: "Text der Ankündigungsleiste",
    "Invalid hex code": "Ungültiger Hex-Code",
    "New theme created": "Neues Theme erstellt",
    "Theme updated": "Thema aktualisiert",
    "Screen theme deleted": "Bildschirmthema gelöscht",
    "Something went wrong": "Etwas ist schief gelaufen",
    "Are you sure you want to delete this theme?":
      "Möchten Sie dieses Thema wirklich löschen?",
    "Delete theme": "Theme löschen",
    "Loading themes": "Themen werden geladen",
    "Upload logo": "Logo hochladen",
    "Remove logo": "Logo entfernen",
    "Upload background image": "Hintergrundbild hochladen",
    "Remove image": "Bild entfernen",
    "Theme not found": "Thema nicht gefunden",
    "It may have been deleted, or you do not have permission to view it":
      "Möglicherweise wurde es gelöscht oder Sie haben keine Berechtigung, es anzuzeigen",
    "Loading theme": "Thema wird geladen",
    "Search themes...": "Themen durchsuchen...",
    "{{totalCount}} Themes": "{{totalCount}} Themen",
    "What's a theme?": "Was ist ein Thema?",
    "Themes allow you to customise the appearance of your configurations":
      "Mit Themes können Sie das Erscheinungsbild Ihrer Konfigurationen anpassen",
    "Clicking this button will begin the process of creating a new one.":
      "Wenn Sie auf diese Schaltfläche klicken, wird mit der Erstellung eines neuen Formulars begonnen.",
    "Give your theme a memorable name":
      "Geben Sie Ihrem Thema einen einprägsamen Namen",
    "This will help you find it later when assigning it to a configuration.":
      "Dies erleichtert Ihnen das spätere Auffinden, wenn Sie es einer Konfiguration zuweisen.",
    "Upload a logo (optional)": "Laden Sie ein Logo hoch (optional)",
    "If you do not upload a logo, a Teamworks logo will appear in its place.":
      "If you do not upload a logo, a Teamworks logo will appear in its place.",
    "Upload a background image (optional)":
      "Upload a background image (freiwillig)",
    "Some configurations make use of a background image. If an image is not uploaded, the background colour selected below will be used.":
      "Einige Konfigurationen nutzen ein Hintergrundbild. Wenn kein Bild hochgeladen wird, wird die unten ausgewählte Hintergrundfarbe verwendet.",
    "Customise the colours you want to use":
      "Passen Sie die Farben an, die Sie verwenden möchten",
    "The 'text' options should be a contrasting colour, to ensure it can be easily read.":
      "Die 'Textoptionen' sollten eine Kontrastfarbe haben, um eine gute Lesbarkeit zu gewährleisten.",
    "Once you are ready, save the theme by clicking this button":
      "Wenn Sie fertig sind, speichern Sie das Thema, indem Sie auf diese Schaltfläche klicken",
    "Your theme will then be available to be selected when managing a configuration.":
      "Ihr Thema steht dann zur Auswahl beim Verwalten einer Konfiguration zur Verfügung.",
    "Theme contrast": "Themenkontrast",
    "Some content appears in containers that are semi transparent and overlayed on your chosen background colour.":
      "Einige Inhalte werden in halbtransparenten Containern angezeigt, die über der von Ihnen gewählten Hintergrundfarbe liegen.",
    "Would you like to lighten or darken your background?":
      "Möchten Sie Ihren Hintergrund aufhellen oder abdunkeln?",
    Dark: "Dunkel",
    Light: "Licht",
    "Theme preview": "Theme preview",
    Close: "Close",
    Preview: "Preview",
  },
  ScreenTemplateNames: {
    "L shape with media": "L-Form mit Medien",
    "Media only": "Nur Medien",
    "Schedule only": "Nur Zeitplan",
    "Multi day schedule": "Mehrtägiger Zeitplan",
    "Multi column schedule": "Mehrspaltiger Zeitplan",
    Basic: "Basic",
  },
  DialogProvider: {
    Cancel: "Stornieren",
    Confirm: "Bestätigen",
    Close: "Schließen",
    Ok: "OK",
    "Close without saving?": "Schließen ohne zu speichern?",
    "Close without saving": "Schließen ohne zu speichern",
    "Any unsaved changes will be lost":
      "Alle nicht gespeicherten Änderungen gehen verloren",
    "Back to form": "Zurück zur Form",
  },
  ConfigurationPicker: {
    Configuration: "Konfiguration",
    "No configurations found for '{{searchText}}'":
      "Keine Konfigurationen für'{{searchText}}' gefunden",
    "No configurations found": "Keine Konfigurationen gefunden",
    "No configuration selected": "Keine Konfiguration ausgewählt",
    "Clear configuration": "Übersichtliche Konfiguration",
    "Searching configurations": "Suche nach Konfigurationen",
  },
  ThemePicker: {
    Theme: "Thema",
    "Clear theme": "Klares Thema",
    "Screen theme": "Bildschirmthema",
    "No theme selected": "Kein Thema ausgewählt",
    "No themes found matching '{{searchText}}'":
      "Es wurden keine Themen gefunden, die zu '{{searchText}}' passen",
    "No themes found": "Keine Themen gefunden",
    "Loading themes...": "Themen werden geladen...",
  },
  MarkdownField: {
    Preview: "Vorschau",
    Remove: "Entfernen",
    "Nothing to preview": "Nichts zur Vorschau",
    Write: "Schreiben",
  },
  ErrorScreen: {
    "Something went wrong": "Something went wrong",
    "Sorry for the inconvience, our engineers have been notified and will be working on a fix":
      "Wir entschuldigen uns für die Unannehmlichkeiten. Unsere Techniker wurden benachrichtigt und werden an einer Lösung arbeiten",
    "If this issue persists, please contact Teamworks customer support":
      "Wenn das Problem weiterhin besteht, wenden Sie sich bitte an den Teamworks-Kundendienst",
    Reload: "Neu laden",
    "Customer support": "Customer support",
  },
  OrganisationSwitcher: {
    "Fetching organisations": "Organisationen abrufen",
    "Select an organisation": "Wählen Sie eine Organisation aus",
    "Switching profile": "Profil wechseln",
  },
  TimeZonePicker: {
    Timezone: "Zeitzone",
  },
  MatchesDrawer: {
    "Add match": "Übereinstimmung hinzufügen",
    "Fetching event": "Ereignis abrufen",
    "Event title": "Veranstaltungstitel",
    "Starts at": "Beginnt um",
    "Create event": "Veranstaltung erstellen",
  },
  FileUploadField: {
    "Upload file": "Datei hochladen",
    "Remove file": "Datei entfernen",
  },
  HubProfilePicker: {
    Search: "Suchen",
    "Clear search text": "Suchtext löschen",
    "Search text too short": "Suchtext zu kurz",
    "Please add at least 3 characters to begin a search":
      "Bitte fügen Sie mindestens 3 Zeichen hinzu, um eine Suche zu starten",
    "No calendars found": "Keine Kalender gefunden",
    "We couldn't find any calendars matching '{{searchText}}'":
      "Wir konnten keine Kalender finden, die mit '{{searchText}}' übereinstimmen",
    "Search...": "Suchen...",
    "Confirm selection": "Auswahl bestätigen",
    Close: "Schließen",
    "Add Teamworks calendars": "Fügen Sie Teamworks-Kalender hinzu",
    "Loading profiles": "Profile laden",
    "Search text must have at least 3 characters":
      "Der Suchtext muss mindestens 3 Zeichen lang sein",
    "No results found": "Keine Ergebnisse gefunden",
    "Calendar details": "Kalenderdetails",
    "Remove calendar": "Kalender entfernen",
    "Are you sure you want to remove this calendar?":
      "Möchten Sie diesen Kalender wirklich entfernen?",
    "No calendars added": "Keine Kalender hinzugefügt",
    "Unknown profile": "Unbekanntes Profil",
    "You don't have access to this hub profile":
      "Sie haben keinen Zugriff auf dieses Hub-Profil",
  },
  ScreenEventsPicker: {
    "Loading events": "Ereignisse werden geladen",
    "Event countdown": "Countdown zum Ereignis",
    "Create event": "Veranstaltung erstellen",
    "Update event": "Ereignis aktualisieren",
    "Event title": "Veranstaltungstitel",
    "Starts at": "Beginnt um",
    "Add opponent badge": "Gegnerabzeichen hinzufügen",
    "Remove opponent badge": "Gegnerabzeichen entfernen",
    "Delete event": "Veranstaltung löschen",
    "Are you sure you want to delete this event?":
      "Sind Sie sicher, dass Sie dieses Ereignis löschen möchten?",
    "Delete now": "Jetzt löschen",
    "Opponent badge position": "Position des gegnerischen Abzeichens",
    "Should the opponent badge be on the left or right?":
      "Sollte das Gegnerabzeichen links oder rechts angebracht sein?",
    "i.e are you playing home or away?":
      "d.h. spielst du zu Hause oder auswärts?",
    "Edit event": "Veranstaltung bearbeiten",
  },
  ScreenConfigDrawer: {
    "Loading configuration": "Konfiguration wird geladen",
    "Number of days the schedule should span. Max 5":
      "Anzahl der Tage, die der Zeitplan umfassen soll. Maximal 5",
    "Number of days": "Anzahl der Tage",
    "Delete configuration": "Konfiguration löschen",
    "Are you sure you want to delete this configuration?":
      "Sind Sie sicher, dass Sie diese Konfiguration löschen möchten?",
    "Configuration deleted": "Konfiguration gelöscht",
    "Something went wrong": "Etwas ist schief gelaufen",
    "Save changes": "Änderungen speichern",
    "Configuration updated": "Konfiguration aktualisiert",
    bottom_content_cards: "Karten",
    bottom_content_cards_desc:
      "Wählen Sie Karten aus, um Text oder Bilder hinzuzufügen",
    media_content: "Medien",
    media_content_desc: "Wählen Sie aus Videos, Bildern und YouTube-Inhalten",
    multi_column_schedule: "Mehrspaltiger Zeitplan",
    multi_column_schedule_desc:
      "Wählen Sie Teamworks-Kalender aus, die auf dem Bildschirm angezeigt werden sollen",
    multi_day_schedule: "Mehrtägiger Zeitplan",
    multi_day_schedule_desc:
      "Wählen Sie Teamworks-Kalender aus, die auf dem Bildschirm angezeigt werden sollen",
    right_schedule: "Zeitplan",
    right_schedule_desc:
      "Wählen Sie Teamworks-Kalender aus, die auf dem Bildschirm angezeigt werden sollen",
    "%future added value": "Unbekannte Komponente",
    "%future added value_desc": "Unbekannte Komponente",
    "Configuration name": "Konfigurationsname",
    "Add event countdown": "Ereignis-Countdown hinzufügen",
    "A countdown will be displayed until the event starts":
      "Bis zum Beginn der Veranstaltung wird ein Countdown angezeigt",
    "Not saved": "Nicht gespeichert",
    "Please fix any errors and try again":
      "Bitte beheben Sie alle Fehler und versuchen Sie es erneut",
    "Give the configuration a name": "Geben Sie der Konfiguration einen Namen",
    "Try to give it a relevant name, to help you quickly find it":
      "Geben Sie ihm einen passenden Namen, damit Sie ihn schnell finden",
    "Pick a theme": "Wählen Sie ein Thema",
    "The theme will set colours and logo for your configuration":
      "Das Thema legt Farben und Logo für Ihre Konfiguration fest",
    "Add an event (optional)": "Eine Veranstaltung hinzufügen (fakultativ)",
    "A countdown will be displayed on the configuration until the event starts":
      "Auf der Konfiguration wird ein Countdown angezeigt, bis die Veranstaltung beginnt",
    "Add a Teamworks schedule": "Fügen Sie einen Teamworks-Zeitplan hinzu",
    "Upload media": "Medien hochladen",
    "Add cards (optional)": "Karten hinzufügen (fakultativ)",
    "Add multiple Teamworks schedules":
      "Fügen Sie mehrere Teamworks-Zeitpläne hinzu",
    "Unknown component": "Unbekannte Komponente",
    "Events from the selected profiles will be shown on the configuration":
      "Ereignisse aus den ausgewählten Profilen werden in der Konfiguration angezeigt",
    "You can upload images, videos or add YouTube videos":
      "Sie können Bilder und Videos hochladen oder YouTube-Videos hinzufügen",
    "The configuration will rotate through the cards one at a time":
      "Die Konfiguration wechselt nacheinander durch die Karten",
    "Each schedule will have its own column on the configuration":
      "Jeder Zeitplan verfügt über eine eigene Spalte in der Konfiguration",
    "A multiday schedule will display the events from the selected profiles across multiple days":
      "Ein mehrtägiger Zeitplan zeigt die Ereignisse der ausgewählten Profile über mehrere Tage hinweg an",
    "Save the configuration": "Speichern Sie die Konfiguration",
    "Once you are happy with your updates, save the changes":
      "Sobald Sie mit Ihren Aktualisierungen zufrieden sind, übernehmen Sie die Änderungen",
    "Take a tour": "Machen Sie eine Tour",
  },
  AnnouncementsScreen: {
    "Announcement text is required": "Ankündigungstext ist erforderlich",
    "Announcements updated": "Ankündigungen aktualisiert",
    "Something went wrong": "Etwas ist schief gelaufen",
    "Announcements allow you to post important information to be displayed in banners across all devices.":
      "Mit Ankündigungen können Sie wichtige Informationen veröffentlichen, die in Bannern auf allen Geräten angezeigt werden.",
    Announcements: "Ankündigungen",
    "Loading announcements": "Ankündigungen werden geladen",
    "Manage announcements": "Ankündigungen verwalten",
    "Announcements are displayed on all screens, at all times":
      "Ankündigungen werden jederzeit auf allen Bildschirmen angezeigt",
    "No announcements yet": "Noch keine Ankündigungen",
    "New announcement": "Neue Ankündigung",
    "Save announcement": "Ankündigung speichern",
    "Note: announcements must be a single line. New lines will be ignored.":
      "Hinweis: Ankündigungen müssen einzeilig sein. Neue Zeilen werden ignoriert.",
    "Delete announcement": "Ankündigung löschen",
    "Are you sure you want to remove this annoucement?":
      "Möchten Sie diese Ankündigung wirklich entfernen?",
    "{{totalCount}} Announcements": "{{totalCount}} 3 Ankündigungen",
    Content: "Inhalt",
    "Last updated": "Zuletzt aktualisiert",
  },
  CardsFormField: {
    "Add card": "Karte hinzufügen",
    "Add image": "Bild hinzufügen",
    "Remove image": "Bild entfernen",
    "Remove card": "Karte entfernen",
    "Are you sure you want to remove this card?":
      "Möchten Sie diese Karte wirklich entfernen?",
    "No cards added": "Keine Karten hinzugefügt",
  },
  MediaFormField: {
    "Invalid YouTube link, please try again":
      "Ungültiger YouTube-Link, bitte versuchen Sie es erneut",
    "File not supported, please upload an image or video":
      "Datei wird nicht unterstützt. Bitte laden Sie ein Bild oder Video hoch",
    "Upload image/video": "Bild/Video hochladen",
    "Add YouTube": "YouTube hinzufügen",
    "Add YouTube video": "YouTube-Video hinzufügen",
    "Video title": "Videotitel",
    "YouTube link": "YouTube-Link",
    Image: "Bild",
    Video: "Video",
    YouTube: "YouTube",
    "Something went wrong, file not uploaded":
      "Es ist ein Fehler aufgetreten, die Datei wurde nicht hochgeladen",
    "Cancel file upload?": "Datei-Upload abbrechen?",
    "Are you sure you want to cancel this upload?":
      "Are you sure you want to cancel this upload?",
    "Upload image": "Bild hochladen",
    "Upload video": "Video hochladen",
    "Uploading {{uploadPct}}%": "Hochladen {{uploadPct}}%",
    "Upload complete": "Hochladen abgeschlossen",
    Name: "Name",
    "Number seconds image displays before moving to next media item":
      "Anzahl der Sekunden, in denen das Bild angezeigt wird, bevor zum nächsten Medienelement gewechselt wird",
    "Duration (seconds)": "Dauer (Sekunden)",
    "Add image": "Bild hinzufügen",
    "Add video": "Video hinzufügen",
    "Delete media": "Medien löschen",
    "Are you sure you want to delete this media?":
      "Sind Sie sicher, dass Sie dieses Medium löschen möchten?",
    "Update duration": "Aktualisierungsdauer",
    "Edit duration": "Dauer bearbeiten",
    "Remove media": "Medien entfernen",
    "No media added": "Keine Medien hinzugefügt",
  },
  MultiColumnScheduleFormField: {
    "Add column": "Spalte hinzufügen",
    "Column title": "Spaltentitel",
    "Remove column": "Spalte entfernen",
    "Are you sure you want to remove this column?":
      "Möchten Sie diese Spalte wirklich entfernen?",
  },
  AuthProvider: {
    "Fetching active users": "Aktive Benutzer abrufen",
    "Setting organisations": "Organisationen festlegen",
    "Logging you in": "Ich melde mich an",
  },
  AccessDeniedScreen: {
    "You don't have access to Digital Displays":
      "Sie haben keinen Zugriff auf Digitalanzeigen",
    "Please contact Teamworks customer support to request access":
      "Bitte wenden Sie sich an den Teamworks-Kundensupport, um Zugriff anzufordern",
    "Sign out": "Abmelden",
    "Customer support": "Kundenbetreuung",
    Reload: "Neu laden",
  },
  DeviceGroupsTab: {
    "Delete group": "Gruppe löschen",
    "Are you sure you want to delete this group?":
      "Sind Sie sicher, dass Sie diese Gruppe löschen möchten?",
    "Device group deleted successfully": "Gerätegruppe erfolgreich gelöscht",
    "Something went wrong": "Etwas ist schief gelaufen",
    "Device group created successfully": "Gerätegruppe erfolgreich erstellt",
    "Group updated": "Gruppe aktualisiert",
    "New group": "Neue Gruppe",
    "Device groups allow you to group your display devices under one name.":
      "Mit Gerätegruppen können Sie Ihre Anzeigegeräte unter einem Namen gruppieren.",
    "Device groups": "Gerätegruppen",
    "{{totalCount}} Device groups": "{{totalCount}} Gerätegruppen",
    "Search groups...": "Gruppen durchsuchen...",
    "Group name": "Gruppenname",
    "Device count": "Geräteanzahl",
    "Fetching groups": "Gruppen abrufen",
    "No groups": "Keine Gruppen",
    "Group name is required": "Gruppenname ist erforderlich",
    "Please select at least 2 devices":
      "Bitte wählen Sie mindestens 2 Geräte aus",
    "Create group": "Gruppe erstellen",
    "Update group": "Gruppe aktualisieren",
    "Fetching devices...": "Geräte werden abgerufen...",
    "Select devices": "Wählen Sie Geräte aus",
    Devices: "Geräte",
    Groups: "Gruppen",
  },
  ErrorState: {
    "Something went wrong": "Etwas ist schief gelaufen",
    "Please try again, or contact Teamworks support if the issue persists":
      "Bitte versuchen Sie es erneut oder wenden Sie sich an den Teamworks-Support, wenn das Problem weiterhin besteht",
  },
  ProductToursProvider: {
    Back: "Zurück",
    Next: "Nächste",
    Finish: "Beenden",
  },
  Drawer: {
    Close: "Schließen",
  },
  InitialSearchParamsProvider: {
    "Initializing URL params": "URL-Parameter werden initialisiert",
  },
  PreviewsProvider: {
    "Redirecting to preview": "Weiterleitung zur Vorschau",
  },
  PlaylistsScreen: {
    Playlists: "Wiedergabelisten",
    "New playlist": "Neue Playlist",
    "{{totalCount}} Playlists": "{{totalCount}} Wiedergabelisten",
    Name: "Name",
    "Loading playlists": "Playlists werden geladen",
    "No playlists have been setup yet":
      "Es wurden noch keine Playlists eingerichtet",
    "Playlists allow you to join multiple configurations together.":
      "Mit Playlists können Sie mehrere Konfigurationen zusammenfügen.",
    "No configurations selected": "Keine Konfigurationen ausgewählt",
    "Add configuration": "Konfiguration hinzufügen",
    Cancel: "Stornieren",
    "Create playlist": "Playlist erstellen",
    "Delete playlist": "Playlist löschen",
    "Are you sure you want to delete this playlist?":
      "Sind Sie sicher, dass Sie diese Playlist löschen möchten?",
    "Loading playlist": "Playlist wird geladen",
    "Save changes": "Änderungen speichern",
    "Configuration already in playlist":
      "Konfiguration bereits in der Playlist",
    "The selected configuration is already part of this playlist, do you want to add it again?":
      "Die ausgewählte Konfiguration ist bereits Teil dieser Playlist. Möchten Sie sie erneut hinzufügen?",
    "Remove from playlist": "Aus Playlist entfernen",
    Configuration: "Konfiguration",
    "Number loops": "Zahlenschleifen",
    Active: "Aktiv",
    "Playlist updated": "Playlist aktualisiert",
    "Something went wrong": "Etwas ist schief gelaufen",
    "Last updated": "Zuletzt aktualisiert",
    "Digital Displays URL": "URL für digitale Anzeigen",
    "You may want to use this hardcoded url to integrate with systems that do not support our QR code setup.":
      "Möglicherweise möchten Sie diese fest codierte URL zur Integration in Systeme verwenden, die unsere QR-Code-Einrichtung nicht unterstützen.",
    "Get hardcoded URL": "Holen Sie sich eine hartcodierte URL",
    l_layout_loop_description:
      "Medienelemente durchlaufen eine bestimmte Anzahl von Malen, bevor sie zur nächsten Konfiguration wechseln",
    media_only_loop_description:
      "Medienelemente durchlaufen eine bestimmte Anzahl von Malen, bevor sie zur nächsten Konfiguration wechseln",
    basic_loop_description:
      "Der Zeitplan durchläuft eine bestimmte Anzahl von Wiederholungen, bevor zur nächsten Konfiguration gewechselt wird",
    multi_column_schedule_loop_description:
      "Der Zeitplan durchläuft eine bestimmte Anzahl von Wiederholungen, bevor zur nächsten Konfiguration gewechselt wird",
    multi_day_schedule_loop_description:
      "Der Zeitplan durchläuft eine bestimmte Anzahl von Wiederholungen, bevor zur nächsten Konfiguration gewechselt wird",
    schedule_only_loop_description:
      "Der Zeitplan durchläuft eine bestimmte Anzahl von Wiederholungen, bevor zur nächsten Konfiguration gewechselt wird",
    "%future added value_loop_description": "Unbekannter Konfigurationstyp",
    mvp_loop_description: "Unbekannter Konfigurationstyp",
    timed_description:
      "Die Konfiguration bleibt für eine bestimmte Zeit auf dem Bildschirm, bevor zur nächsten Konfiguration gewechselt wird",
    Looped: "Geschleift",
    Timed: "Zeitgesteuert",
    "Duration (minutes)": "Dauer (Minuten)",
    "Edit configuration": "Konfiguration bearbeiten",
    "New configuration": "Neue Konfiguration",
    "Loading configuration": "Konfiguration wird geladen",
    "Add an existing configuration to the playlist":
      "Vorhandene Konfiguration zur Wiedergabeliste hinzufügen",
    "Create a new configuration to add to the playlist":
      "Neue Konfiguration erstellen, um sie zur Wiedergabeliste hinzuzufügen",
  },
  PlaylistPicker: {
    Playlist: "Wiedergabeliste",
    "Clear playlist": "Klare Playlist",
    "No playlist selected": "Keine Playlist ausgewählt",
    "Searching playlists": "Durchsuchen von Wiedergabelisten",
    "No playlists found for '{{searchText}}'":
      "Keine Playlists für '{{searchText}}' gefunden",
    "No playlists found": "Keine Playlists gefunden",
  },
};
