/* eslint-disable no-restricted-syntax */
import { Region } from "../helpers/types";

type StoredValues = {
  secureToken: string;
  currentOrgId: string;
  currentRegion: Region;
  afterLoginPath: string;
};

export class LocalStorage {
  static get<K extends keyof StoredValues>(key: K) {
    const json = localStorage.getItem(key);
    if (json == null) return undefined;

    try {
      return JSON.parse(json).data as StoredValues[K];
    } catch {
      return undefined;
    }
  }
  static set<K extends keyof StoredValues>(
    key: K,
    value: StoredValues[K] | undefined,
  ) {
    if (value == null) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify({ data: value }));
    }
  }
  static clear() {
    localStorage.clear();
  }
}
