import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { LoadingScreen } from "../components/LoadingScreen";
import { useTranslation } from "react-i18next";

type DisplaysSearchParams = {
  source: "tw_web_view" | "an unknown source" | null;
  // axleToken: sets current user
  axleToken: string | null;
  // axleRefreshToken: sets current user, only if not already auth'd
  axleRefreshToken: string | null;
};

const InitialSearchParamsContext = createContext<DisplaysSearchParams>(null!);

export const InitialSearchParamsProvider = ({
  children,
}: PropsWithChildren) => {
  const searchParams = new URL(window.location.href).searchParams;
  const [initialSearchParams, setInitialSearchParams] =
    useState<DisplaysSearchParams>();
  const { t } = useTranslation("InitialSearchParamsProvider");

  useEffect(() => {
    if (initialSearchParams == null) {
      setInitialSearchParams({
        source: searchParams.get("source") as DisplaysSearchParams["source"],
        axleToken: searchParams.get("axleToken"),
        axleRefreshToken: searchParams.get("axleRefreshToken"),
      });
    }
  }, [searchParams, initialSearchParams]);

  if (initialSearchParams == null) {
    return <LoadingScreen message={t("Initializing URL params")} />;
  }

  return (
    <InitialSearchParamsContext.Provider value={initialSearchParams}>
      {children}
    </InitialSearchParamsContext.Provider>
  );
};

export const useInitialSearchParams = () => {
  const ctx = useContext(InitialSearchParamsContext);
  if (ctx == null) {
    throw new Error("useInitialSearchParams called before ready");
  }

  return ctx;
};
