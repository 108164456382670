import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { throttle } from "lodash";

export type ScreenSize = "sm" | "md" | "lg";

const ScreenSizeContext = createContext<ScreenSize>(null!);

export const ScreenSizeProvider = (props: PropsWithChildren) => {
  const [size, setSize] = useState<ScreenSize>();

  useEffect(() => {
    const setScreenSize = throttle(() => {
      setSize(
        window.innerWidth > 1200 ? "lg" : window.innerWidth > 750 ? "md" : "sm",
      );
    }, 100);

    setScreenSize();
    window.addEventListener("resize", setScreenSize);
    return () => window.removeEventListener("resize", setScreenSize);
  }, []);

  if (size == null) {
    return null;
  }

  return <ScreenSizeContext.Provider value={size} {...props} />;
};

export function useScreenSize() {
  const context = useContext(ScreenSizeContext);
  if (context == null) {
    throw new Error(
      "Used useScreenSize outside of a component tree wrapped in a ScreenSizeProvider",
    );
  }
  return context;
}
