import { config } from "../config";
import { Region } from "./types";

export const baseUrlForRegion = (region: Region) => {
  const baseUrl = config.apiBaseUrls.find(
    (u) => u.region === region.toLowerCase(),
  )?.url;
  if (baseUrl == null) {
    throw new Error(`API url not found for region: ${region}`);
  }
  return baseUrl;
};
