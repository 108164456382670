import posthog from "posthog-js";
import { config } from "../config";
import { ID } from "./types";

declare global {
  interface Window {
    // Gainsight
    aptrinsic?: (action: string, userTag?: unknown, orgTags?: unknown) => void;
  }
}

export const analytics = {
  identify(
    id: ID,
    postHogTags: { [key: string]: string },
    gainsightUserTags: { [key: string]: string },
    gainsightOrgTags: { [key: string]: string },
  ) {
    if (config.isDevelopment) {
      return console.info("analytics identify", {
        id,
        postHogTags,
        gainsightUserTags,
        gainsightOrgTags,
      });
    }

    if (config.posthog.enabled) {
      posthog.identify(id, postHogTags);
    }
    window.aptrinsic?.(
      "identify",
      { id: id, ...gainsightUserTags },
      gainsightOrgTags,
    );
  },
  initialize() {
    if (config.isDevelopment) {
      return console.info("analytics initialize");
    }

    if (config.posthog.enabled) {
      posthog.init(config.posthog.apiKey, {
        api_host: config.posthog.hostname,
      });
    }
  },
  reset() {
    if (config.isDevelopment) {
      return console.info("analytics reset");
    }

    try {
      if (config.posthog.enabled) {
        posthog.reset();
      }
      window.aptrinsic?.("reset");
    } catch (error) {
      console.log(error);
    }
  },
};
