import { getContrastRatio } from "@mui/material";
import { HexColor } from "./types";

type RBGAColor = string;
type RBGColorComponents = { r: number; g: number; b: number };

export const inter = `Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`;

export const fontSize = {
  lawyer: 11,
  detail: 13,
  small: 14,
  body: 16,
  large: 18,
  h4: 20,
  h3: 24,
  h2: 30,
  h1: 36,
  h0: 48,
};

export const colors = {
  white: "#FFFFFF" as HexColor,
  gray1: "#F8FAFB" as HexColor,
  gray2: "#F3F4F7" as HexColor,
  gray3: "#EBECF0" as HexColor,
  gray4: "#E1E1E1" as HexColor,
  gray5: "#9E9E9E" as HexColor,
  gray6: "#636464" as HexColor,
  black: "#000000" as HexColor,
  red1: "#FBECEB" as HexColor,
  red2: "#EFB1AD" as HexColor,
  red3: "#D93D31" as HexColor,
  blue1: "#34AFFF" as HexColor,
  blue2: "#1A6AFF" as HexColor,
};

export const iconSize = {
  smaller: 12,
  small: 16,
  regular: 20,
  medium: 24,
  large: 40,
  larger: 68,
};

export type IconSize = keyof typeof iconSize;

export const borderRadius = {
  small: 2,
  regular: 6,
  medium: 8,
  large: 18,
};

export const spacing = {
  none: 0,
  tiny: 4,
  smallest: 8,
  smaller: 12,
  small: 16,
  medium: 24,
  large: 32,
  larger: 48,
  huge: 64,
  gigantic: 80,
};

export const rgb = (hex: HexColor): RBGColorComponents => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex?.toLowerCase(),
  );
  if (result == null) {
    throw new Error(`Hex: ${hex}`);
  }
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
};

export const toRgba = (hex: HexColor, opacity: number): RBGAColor => {
  const { r, g, b } = rgb(hex);
  return `rgba(${r},${g},${b},${opacity})` as RBGAColor;
};

export const isDark = (hex: string) =>
  getContrastRatio(colors.white, hex) > 4.5;

export type Spacing = keyof typeof spacing;
