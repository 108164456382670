import React from "react";
import { CSSProperties, forwardRef, ReactNode } from "react";
import { Spacing, spacing } from "../helpers/theme";
import { HexColor } from "../helpers/types";

export type FlexProps = React.HTMLAttributes<HTMLDivElement> & {
  direction?: CSSProperties["flexDirection"];
  alignItems?: CSSProperties["alignItems"];
  backgroundColor?: HexColor;
  children?: ReactNode;
  flex?: CSSProperties["flex"];
  fullWidth?: boolean;
  fullHeight?: boolean;
  gap?: Spacing;
  grow?: CSSProperties["flexGrow"];
  wrap?: CSSProperties["flexWrap"];
  justifyContent?: CSSProperties["justifyContent"];
  padding?: Spacing;
  paddingHorizontal?: Spacing;
  paddingVertical?: Spacing;
  style?: CSSProperties;
};

export const Column = forwardRef<HTMLDivElement, FlexProps>(
  (
    {
      alignItems,
      backgroundColor,
      children,
      direction,
      fullWidth,
      fullHeight,
      gap,
      justifyContent,
      padding,
      paddingHorizontal,
      paddingVertical,
      style,
      grow,
      wrap,
      ...props
    }: FlexProps,
    ref,
  ) => {
    return (
      <div
        {...props}
        ref={ref}
        style={{
          alignItems,
          backgroundColor: backgroundColor ?? undefined,
          display: "flex",
          flexDirection: direction ?? "column",
          flexGrow: grow,
          flexWrap: wrap,
          gap: gap ? spacing[gap] : undefined,
          justifyContent,
          padding: padding ? spacing[padding] : undefined,
          ...(paddingHorizontal
            ? {
                paddingLeft: spacing[paddingHorizontal],
                paddingRight: spacing[paddingHorizontal],
              }
            : {}),
          ...(paddingVertical
            ? {
                paddingBottom: spacing[paddingVertical],
                paddingTop: spacing[paddingVertical],
              }
            : {}),
          width: fullWidth ? "100%" : undefined,
          height: fullHeight ? "100%" : undefined,
          ...style,
        }}
        {...props}
      >
        {children}
      </div>
    );
  },
);

Column.displayName = "Column";

export const Row = forwardRef<HTMLDivElement, FlexProps>(
  ({ style, ...props }, ref) => {
    return (
      <Column ref={ref} {...props} style={{ flexDirection: "row", ...style }} />
    );
  },
);

Row.displayName = "Row";
