import React, { PropsWithChildren, useMemo } from "react";
import {
  ThemeProvider as MuiThemeProvider,
  Theme,
  createTheme,
} from "@mui/material";
import { useCurrentOrganisation } from "./CurrentProvider";
import { inter } from "../helpers/theme";

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

export const ThemeProvider = (props: PropsWithChildren) => {
  const org = useCurrentOrganisation();

  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: inter,
        },
        palette: {
          primary: {
            main: org.navbarTopColor,
          },
          secondary: {
            main: org.navbarBottomColor,
          },
        },
      }),
    [org],
  );

  return <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>;
};
