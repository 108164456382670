import React from "react";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import { spacing, Spacing } from "../helpers/theme";

type DivProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;
export const Spacer = ({
  size,
  axis,
  style,
  ...props
}: {
  axis?: "horizontal" | "vertical";
  size: Spacing | "grow";
} & DivProps) => {
  const grow = size === "grow";
  const pixels = grow ? undefined : spacing[size];
  const width = axis === "vertical" ? 1 : pixels;
  const height = axis === "horizontal" ? 1 : pixels;
  return (
    <span
      style={{
        display: "block",
        flexShrink: 0,
        flexGrow: grow ? 1 : undefined,
        width,
        minWidth: width,
        height,
        minHeight: height,
        ...style,
      }}
      {...props}
    />
  );
};
