import React, { useEffect, useState } from "react";
import { colors } from "../helpers/theme";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { AppText } from "./Typography";
import { Column } from "./Flex";
import classNames from "classnames";
import { LoadingSpinner } from "./LoadingSpinner";

const useClasses = makeStyles({
  container: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    height: "100%",
    width: "100%",
    flexGrow: 1,
    backgroundColor: colors.white,
  },
  grayTheme: {
    backgroundColor: colors.gray1,
  },
});

export const LoadingScreen = (props: {
  message: string;
  theme?: "light-content" | "gray-content";
  size?: "small" | "medium" | "large";
}) => {
  const classes = useClasses();
  const [hasTimedOut, setTimedOut] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setTimedOut(true), 200);
    return () => {
      clearTimeout(timer);
    };
  }, [setTimedOut]);
  return (
    <Box
      className={classNames(
        classes.container,
        props.theme === "gray-content" ? classes.grayTheme : undefined,
      )}
    >
      {hasTimedOut && (
        <Column
          alignItems="center"
          gap="small"
          justifyContent="center"
          paddingVertical="gigantic"
        >
          <LoadingSpinner size={props.size ?? "large"} />
          <AppText>{props.message}</AppText>
        </Column>
      )}
    </Box>
  );
};
