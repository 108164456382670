/**
 * @generated SignedSource<<7ccb9402fd73d58d06805c1c03a2b5e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AccessibleWebApp = "academics" | "ams" | "arms" | "communities" | "hub" | "inflcr" | "nutrition" | "pathways" | "pulse" | "strength" | "trac" | "tv" | "wallet" | "%future added value";
export type CurrentProviderQuery$variables = Record<PropertyKey, never>;
export type CurrentProviderQuery$data = {
  readonly currentOrganisation: {
    readonly activeFeatureFlags: ReadonlyArray<string>;
    readonly id: string;
    readonly name: string;
    readonly navbarBottomColor: string;
    readonly navbarImageUrl: string;
    readonly navbarTopColor: string;
    readonly permittedIpAddresses: ReadonlyArray<string>;
  };
  readonly currentUser: {
    readonly accessibleWebApps: ReadonlyArray<AccessibleWebApp>;
    readonly avatarUrl: string | null | undefined;
    readonly fullName: string;
    readonly id: string;
    readonly initials: string;
  };
};
export type CurrentProviderQuery = {
  response: CurrentProviderQuery$data;
  variables: CurrentProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "currentUser",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "initials",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "avatarUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accessibleWebApps",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Organisation",
    "kind": "LinkedField",
    "name": "currentOrganisation",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "navbarBottomColor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "navbarTopColor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "navbarImageUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permittedIpAddresses",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "activeFeatureFlags",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CurrentProviderQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CurrentProviderQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "48086bdeb5d2abeea8328d2ea9bf3aa0",
    "id": null,
    "metadata": {},
    "name": "CurrentProviderQuery",
    "operationKind": "query",
    "text": "query CurrentProviderQuery {\n  currentUser {\n    id\n    fullName\n    initials\n    avatarUrl\n    accessibleWebApps\n  }\n  currentOrganisation {\n    id\n    name\n    navbarBottomColor\n    navbarTopColor\n    navbarImageUrl\n    permittedIpAddresses\n    activeFeatureFlags\n  }\n}\n"
  }
};
})();

(node as any).hash = "ebf764d4138eb73bdead79bcf9d9759c";

export default node;
