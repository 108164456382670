import React, { Suspense, useEffect, useState } from "react";
import { Dialog, Divider } from "@mui/material";
import { Column } from "./Flex";
import { Heading } from "./Typography";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import { UserProfile, useAuthenticatedAuth } from "../providers/AuthProvider";
import { LoadingScreen } from "./LoadingScreen";
import { graphql, useLazyLoadQuery } from "react-relay";
import { OrganisationSwitcherQuery } from "./__generated__/OrganisationSwitcherQuery.graphql";

export const OrganisationSwitcher = (props: {
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation("OrganisationSwitcher");
  const { allUserProfiles } = useAuthenticatedAuth();
  const [switchingToProfile, setSwitchingToProfile] = useState<UserProfile>();

  return (
    <Dialog
      fullWidth
      open={props.open || switchingToProfile != null}
      onClose={props.onClose}
    >
      {switchingToProfile != null ? (
        <Suspense fallback={<LoadingScreen message={t("Switching profile")} />}>
          <ProfileSwitcher profile={switchingToProfile} />
        </Suspense>
      ) : (
        <Column padding="medium" gap="small">
          <Heading>{t("Select an organisation")}</Heading>
          <Divider />
          {allUserProfiles.map((profile) => (
            <Button
              onClick={() => setSwitchingToProfile(profile)}
              key={profile.profileId}
              variant="secondary"
            >
              {profile.orgName}
            </Button>
          ))}
        </Column>
      )}
    </Dialog>
  );
};

const ProfileSwitcher = ({ profile }: { profile: UserProfile }) => {
  const { t } = useTranslation("OrganisationSwitcher");
  const { setActiveUserProfile } = useAuthenticatedAuth();
  const { secureToken } = useLazyLoadQuery<OrganisationSwitcherQuery>(
    graphql`
      query OrganisationSwitcherQuery($profileId: String!) {
        secureToken(profileId: $profileId)
      }
    `,
    {
      profileId: profile.profileId,
    },
  );

  useEffect(() => {
    if (secureToken != null) {
      setActiveUserProfile(profile, secureToken);
    }
  }, [secureToken, profile]);

  return <LoadingScreen message={t("Switching profile")} />;
};
