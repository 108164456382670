import React, { ReactNode, useMemo } from "react";
import {
  SnackbarProvider as NotistackbarProvier,
  useSnackbar,
} from "notistack";
import { Column } from "../components/Flex";
import { Label } from "../components/Typography";
import { makeStyles } from "@mui/styles";
import { Alert } from "@mui/material";
import { useScreenSize } from "./ScreenSizeProvider";
import classNames from "classnames";

const useClasses = makeStyles({
  container: {
    zIndex: 9999,
    "@media print": {
      display: "none",
    },
  },
  containerMobile: {
    // height of navbar
    marginBottom: 64,
  },
});

export function SnacksProvider(props: { children: ReactNode }) {
  const classes = useClasses();
  const screenSize = useScreenSize();
  return (
    <NotistackbarProvier
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      classes={{
        containerRoot: classNames(
          classes.container,
          screenSize === "sm" ? classes.containerMobile : undefined,
        ),
      }}
      domRoot={document.getElementById("snacks-provider") ?? undefined}
    >
      {props.children}
    </NotistackbarProvier>
  );
}

export function useSnacks() {
  const snackbar = useSnackbar();
  return useMemo(
    () => ({
      // When running in tests snackbar will be null unless the snackbar provider wraps the test component
      close: snackbar?.closeSnackbar,
      custom: snackbar?.enqueueSnackbar,
      error(title: string, body?: ReactNode): void {
        snackbar.enqueueSnackbar(title, {
          content(key, message) {
            return (
              <Alert severity={"error"}>
                <Column gap={"tiny"}>
                  <Label>{message}</Label>
                  {body}
                </Column>
              </Alert>
            );
          },
        });
      },
      info(title: string, body?: ReactNode) {
        snackbar.enqueueSnackbar(title, {
          content(key, message) {
            return (
              <Alert severity={"info"}>
                <Column gap={"tiny"}>
                  <Label>{message}</Label>
                  {body}
                </Column>
              </Alert>
            );
          },
        });
      },
      success(title: string, body?: ReactNode): void {
        snackbar.enqueueSnackbar(title, {
          content(key, message) {
            return (
              <Alert severity={"success"}>
                <Column gap={"tiny"}>
                  <Label>{message}</Label>
                  {body}
                </Column>
              </Alert>
            );
          },
        });
      },
    }),
    [snackbar],
  );
}
