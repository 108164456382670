import React, { useState } from "react";
import { Row } from "../components/Flex";
import { AppText, Heading, HelperText } from "../components/Typography";
import { Button } from "../components/Button";
import { Spacer } from "../components/Spacer";
import { useTranslation } from "react-i18next";
import { config } from "../config";
import { ErrorLayout } from "../components/ErrorLayout";
import { TeamworksWordmark } from "../assets/TeamworksLogo";
import { iconSize } from "../helpers/theme";
import { Logout, Refresh, SupportAgent } from "@mui/icons-material";
import appJson from "../assets/app.json";

export const AccessDeniedScreen = (props: { handleLogout?: () => void }) => {
  const { t } = useTranslation("AccessDeniedScreen");
  const [loading, setLoading] = useState(false);

  return (
    <ErrorLayout>
      <Spacer size="gigantic" />
      <Row>
        <TeamworksWordmark style={{ height: iconSize.medium }} />
      </Row>
      <Spacer size="medium" />
      <Heading>{t("You don't have access to Digital Displays")}</Heading>
      <Spacer size="small" />
      <AppText>
        {t("Please contact Teamworks customer support to request access")}
      </AppText>
      <Spacer size="large" />

      <Row>
        {props.handleLogout != null ? (
          <Button
            startIcon={Logout}
            loading={loading}
            onClick={() => {
              props.handleLogout?.();
              setLoading(true);
            }}
          >
            {t("Sign out")}
          </Button>
        ) : (
          <Button
            startIcon={Refresh}
            loading={loading}
            onClick={() => {
              setLoading(true);
              window.location.href = "/";
            }}
          >
            {t("Reload")}
          </Button>
        )}
      </Row>
      <Spacer size="gigantic" />
      <Row alignItems="flex-end" justifyContent="space-between" gap="small">
        <Button
          variant="secondary"
          startIcon={SupportAgent}
          onClick={() => window.open(config.customerSupportLink, "_blank")}
        >
          {t("Customer support")}
        </Button>
        <HelperText>v{appJson.version}</HelperText>
      </Row>
    </ErrorLayout>
  );
};
